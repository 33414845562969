<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <form action="" @submit.prevent="onSubmit()">
                    <div v-if="show_hertbeat_url == false">
                    <b-row>
                        <b-col class="font-weight-bold col-md-3 col-12">
                            Monitor Type
                            <span class="font-danger">*</span>
                        </b-col>
                        <b-col class="col-md-4 col-12">
                            <b-form-select name="type" size="sm" v-model="monitor_input.type" :options="monitor_type_opt" :state="validate_type" :disabled="edit_monitor" required>
                                <b-form-select-option value="6" :disabled="not_premium" title="paid">Page Speed</b-form-select-option>
                                <b-form-select-option value="7" :disabled="not_premium" title="paid">Page Screenshot</b-form-select-option></b-form-select>
                            <b-form-invalid-feedback :state="validate_type">Select Monitor type</b-form-invalid-feedback>
                        </b-col>
                        <b-col v-if="edit_monitor">
                           Created at : {{ changeDateFormate(monitor_input.createdAt) }}
                        </b-col>
                    </b-row><hr>
                    </div>
                    <!-- Show HTTPS Monitor  Start-->
                    <Transition name="nested" duration="550">
                    <div class="outer" v-show="monitor_input.type != null">
                        <div class="inner">
                        <b-row class="mb-2">
                        <b-col md="3" class="font-weight-bold">
                            Friendly Name
                            <span class="font-danger">*</span>
                        </b-col>
                        <b-col md="4">
                            <b-form-input size="sm" type="text" name="mname" v-model="monitor_input.name" :state="isNameEntered ? !invalid_fname_message : null"  @input="validateName" maxlength="50" placeholder="Name" required></b-form-input>
                            <b-form-invalid-feedback v-if="isNameEntered">{{ invalid_fname_message }}</b-form-invalid-feedback>
                        </b-col>
                    </b-row>
                    <hr>
                    <div v-if="monitor_input.type != 5">
                        <b-row class="">
                            <b-col md="3" class="font-weight-bold">
                                URL (or IP)
                                <span class="font-danger">*</span>
                            </b-col>
                            <b-col md="6">
                                <b-form-input size="sm" type="text" v-model="monitor_input.url" maxlength="500" :state="isUrlEntered ? !invalid_url_message : null" @input="validate_url" :placeholder="inputPlaceholder" required></b-form-input>
                                <b-form-invalid-feedback v-if="isUrlEntered">{{ invalid_url_message }}</b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                        <hr>
                    </div>
                    <div v-if="monitor_input.type == 4">
                    <b-row class="">
                        <b-col md="3" class="font-weight-bold">
                            Port
                            <span class="font-danger">*</span>
                        </b-col>
                        <b-col md="4">
                            <div class="form-group">
                            <b-form-select size="sm" v-model="monitor_input.port" :options="port_opt" :state="isPortSelected ? !invalid_port_message : null" @change="validate_port" required></b-form-select>
                            <b-form-invalid-feedback v-if="isPortSelected">
                                {{ invalid_port_message }}
                            </b-form-invalid-feedback>
                            </div>
                        </b-col>
                    </b-row>
                    <hr>
                    <div>
                    <b-row class="">
                        <b-col md="3" class="font-weight-bold">
                            Custom Port
                        </b-col>
                        <b-col md="4">
                            <div class="form-group">
                                <b-form-input size="sm" type="number" :state="validatePort" v-model="monitor_input.custom_port" placeholder="custom port" :disabled="show_custom_port" required></b-form-input>
                            </div>
                        </b-col>
                    </b-row>
                    <hr>
                   </div>
                   </div>
                   
                    <div v-if="monitor_input.type == 2">
                        <b-row class="">
                            <b-col md="3" class="font-weight-bold">
                                Keyword
                                <span class="font-danger">*</span>
                            </b-col>
                            <b-col md="4">
                                <b-form-input size="sm" type="text" v-model="monitor_input.keyword" maxlength="1000" :state="isKeywordEntered ? !invalid_keyword_message : null" @input="validate_keyword" placeholder="Keyword" required></b-form-input>
                                <b-form-invalid-feedback v-if="isKeywordEntered">{{ invalid_keyword_message }}</b-form-invalid-feedback>
                            </b-col>
                            <span class="info_txt">(the keyword must be present in the HTML source)</span>
                        </b-row><hr>
                        <b-row class="">
                        <b-col md="3" class="font-weight-bold">
                            Case-insensitive
                        </b-col>
                        <b-col md="4">
                            <input class="checkbox_animated" name="case_sensitive" type="checkbox" v-model="monitor_input.case_sensitive">
                        </b-col>
                        </b-row><hr>
                        <b-row class="">
                        <b-col md="3" class="font-weight-bold">
                            Alert When
                        </b-col>
                        <b-col md="8">
                            <span :class="monitor_input.alertWhen == 1 ? 'mul-select-btn-active': 'mul-select-btn-inactive'"  @click="monitor_input.alertWhen = 1">Keyword Exists</span>
                            <span :class="monitor_input.alertWhen == 2 ? 'mul-select-btn-active': 'mul-select-btn-inactive'"  @click="monitor_input.alertWhen = 2">Keyword Not Exists</span>
                        </b-col>
                        </b-row><hr>
                    
                    </div>
                    <b-row>
                        <b-col md="3" class="font-weight-bold">
                            Select Alert Contacts To Notify
                        </b-col>
                        <b-col md="6">
                            <multiselect
                            size="sm"
                            v-model="alertContacts"
                            tag-placeholder="Add this as new tag"
                            placeholder="Select alerts"
                            label="text"
                            track-by="value"
                            :options="alerts"
                            :multiple="true"
                            :taggable="true"
                            @tag="addTag"
                            >
                            <template #option="{ option }">
                                <span v-if="option.icon" :class="option.icon" style="margin-right: 8px;"></span>
                                {{ option.text }}
                            </template>
                            <template #selected="{ option }">
                                <span v-if="option.icon" :class="option.icon" style="margin-right: 8px;"></span>
                                {{ option.text }}
                            </template>
                            </multiselect>
                        </b-col>
                    </b-row><hr>

                    <b-row>
                        <b-col class="font-weight-bold col-md-3 col-12">
                            Alert Frequency
                        </b-col>
                        <b-col class="col-md-6 col-12">
                            <b-form-select name="type" size="sm" v-model="monitor_input.downAlertFreqType" :options="alert_freq_opt" required>
                                <b-form-select-option value=1 :disabled="not_premium" title="paid">{{not_premium?'once per hour (pro plan)':'once per hour'}}</b-form-select-option>
                                <b-form-select-option value=2 :disabled="not_premium" title="paid">{{not_premium?'once per day (pro plan)':'once per day'}}</b-form-select-option>
                                <b-form-select-option value=3 :disabled="not_premium" title="paid">{{not_premium?'once per week (pro plan)':'once per week'}}</b-form-select-option>
                                <b-form-select-option value=4 :disabled="not_premium" title="paid">{{not_premium?'once per month (pro plan)':'once per month'}}</b-form-select-option>
                            </b-form-select>
                        </b-col>
                    </b-row><hr>
                    <b-row class="">
                        <b-col md="3" class=" font-weight-bold">
                            Monitoring Interval
                        </b-col>
                        <b-col md="4" v-if="monitor_input.type != 5">
                            <!-- <VueSlideBar
                            size="sm"
                            v-model="monitor_input.interval"
                            :min="5"
                            :max="1440"
                            :range="interval.range"
                            :data="interval.data"
                            :state="interval_validate"
                            ></VueSlideBar> -->
                            <!-- <div v-if="monitor_input.type == 6">
                                <div class="slidecontainer">
                                    <input type="range" min="60" max="1440" v-model="monitor_input.interval" class="slider" id="myRange">
                                </div>
                             </div>
                            <div v-else-if="(monitor_input.type == 7)">
                                <div class="slidecontainer">
                                    <input type="range" min="180" max="1440" v-model="monitor_input.interval" class="slider" id="myRange">
                                </div>
                            </div>
                            <div v-else>
                                <div class="slidecontainer">
                                <input type="range" :min="plan_details.minIntervalLimit" max="1440" v-model="monitor_input.interval" class="slider" id="myRange">
                                </div>
                            </div> -->
                            <vue-slider v-if="monitor_input.type == 6" :data="interval_datas_page_load" v-model="monitor_input.interval" :marks="false" :hide-label="true" :tooltip="'none'"></vue-slider>
                            <vue-slider v-else-if="monitor_input.type == 7" :data="interval_datas_screen_shot" v-model="monitor_input.interval" :marks="false" :hide-label="true" :tooltip="'none'"></vue-slider>
                            <vue-slider v-else :data="interval_datas" v-model="monitor_input.interval" :marks="false" :hide-label="true" :tooltip="'none'" @change="checkIntervallimit" :key="sliderKey"></vue-slider>
                        </b-col>
                        <b-col v-if="monitor_input.type != 5">
                            <div v-if="monitor_input.type == 6">
                                <span v-if="monitor_input.interval >=60">every {{Math.round(this.monitor_input.interval /60,2) + ' hours'}}</span>
                            </div>
                            <div v-else-if="(monitor_input.type == 7)">
                                <span v-if="monitor_input.interval >=180">every {{Math.round(this.monitor_input.interval /60,2) + ' hours'}}</span>
                            </div>
                            <div v-else>
                                <span v-if="monitor_input.interval <60">every {{monitor_input.interval}} minutes</span>
                                <span v-if="monitor_input.interval >=60">every {{Math.round(this.monitor_input.interval /60,2) + ' hours'}}</span>
                            </div>
                        </b-col>

                        <b-col v-if="monitor_input.type == 5">
                            <b-row>
                                <b-col cols="4">
                                    <b-form-select name="type" size="sm" v-model="interval_type" :options="interval_opt" @input="monitor_input.interval = monitor_input.dayCount = 1"></b-form-select>
                                </b-col>
                                <b-col cols="4">
                                    <b-form-select v-if="interval_type == 'Minute'" name="type" size="sm" v-model="monitor_input.interval" :options="interval_datas_heartbeat_minutes" ></b-form-select>
                                    <b-form-select v-if="interval_type == 'Hour'" name="type" size="sm" v-model="monitor_input.interval" :options="interval_datas_heartbeat_hours" ></b-form-select>
                                    <b-input name="dayCount" type="number" size="sm" v-if="interval_type == 'Day'" v-model="monitor_input.dayCount" :min="1"></b-input>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <hr>
                    <div v-show="monitor_input.type != 3 && monitor_input.type != 5">
                    <b-row class="">
                        <b-col md="3" class="font-weight-bold">
                            Monitor Timeout
                        </b-col>
                        <b-col md="4">
                            <!-- <VueSlideBar
                            size="sm"
                            v-model="monitor_input.timeout"
                            :min="1"
                            :max="60"
                            :range="timeout.range"
                            :state="interval_timeout"
                            ></VueSlideBar> -->
                            <div class="slidecontainer">
                                <vue-slider :data="timeout_datas" v-model="monitor_input.timeout" :marks="false" :hide-label="true" :tooltip="'none'"></vue-slider>
                            <!-- <input type="range" min="30" max="120" v-model="monitor_input.timeout" class="slider" id="myRange"> -->
                            </div>
                            <!-- <span v-if="(monitor_input.timeout >=60)">in {{(monitor_input.timeout/60)}} minute</span> -->
                        </b-col>
                        <b-col>
                            <span>in {{monitor_input.timeout}} sec</span>
                        </b-col>
                    </b-row>
                    <hr>
                    </div>
                    <div v-show="monitor_input.type == 5">
                    <b-row class="">
                        <b-col md="3" class="font-weight-bold">
                            Grace Timeout
                        </b-col>
                        <b-col md="4">
                            <!-- <div class="slidecontainer"> -->
                                <!-- <b-input type="text" v-model="monitor_input.grace_interval"></b-input> -->
                                <!-- <input type="range" min="30" max="86400" v-model="monitor_input.grace_interval" class="slider" id="myRange"></input> -->
                            <!-- </div> -->
                            <vue-slider :data="grace_timeout_datas" v-model="monitor_input.grace_interval" :marks="false" :hide-label="true" :tooltip="'none'"></vue-slider>
                        </b-col>
                        <b-col md="3">
                            <!-- <b-select v-model="grace_time_in">
                                <option value="1" selected>Second</option>
                                <option value="60">Minute</option>
                                <option value="3600">Hour</option>
                            </b-select> -->
                            {{ fancyTimeFormat(monitor_input.grace_interval) }}
                            <span v-if="monitor_input.grace_interval < 60"> sec</span>
                            <span v-else-if="monitor_input.grace_interval >=3600"> hour</span>
                            <span v-else-if="monitor_input.grace_interval >=60 && monitor_input.grace_interval <3600">  minutes</span>
                        </b-col>
                    </b-row>
                    <hr>
                    </div>
                    <div v-if="(monitor_input.type == 7)">
                        <b-row>
                        <b-col cols="3" class="font-weight-bold">
                            Device Type
                            <span class="font-danger">*</span>
                        </b-col>
                        <b-col cols="4">
                            <b-form-select name="devicetype" size="sm" v-model="monitor_input.devicetype" :options="device_type_opt" :state="isDeviceTypeSelected ? !invalid_devicetype_message : null" @change="validate_deviceType" required></b-form-select>
                            <b-form-invalid-feedback v-if="isDeviceTypeSelected">
                                {{ invalid_devicetype_message }}
                            </b-form-invalid-feedback>
                        </b-col>
                    </b-row><hr>
                    </div>
                    <div v-show="(monitor_input.type != 3 && monitor_input.type != 4 && monitor_input.type != 5 && monitor_input.type != 6 && monitor_input.type != 7 && show_SSL)">
                    <b-row class="">
                        <b-col md="3" class="font-weight-bold">
                            Monitor SSL errors
                        </b-col>
                        <b-col md="1">
                            <input class="checkbox_animated" name="ssl_error" type="checkbox" v-model="monitor_input.ssl_error">
                        </b-col>
                        <!-- <b-col md="">
                            <span class="info_txt">Monitor URL needs to start with "https" for the SSL monitoring to work.</span>
                        </b-col> -->
                    </b-row>
                    <hr>
                    </div>
                    <div v-show="(monitor_input.type != 3 && monitor_input.type != 4 && monitor_input.type != 5 && monitor_input.type != 6 && monitor_input.type != 7 && show_SSL)">
                    <b-row class="" >
                        <b-col md="3" class="font-weight-bold">
                            Enable SSL expiry reminders
                        </b-col>
                        <b-col md="1">
                            <input class="checkbox_animated" name="ssl_expiry_reminder" type="checkbox" v-model="monitor_input.ssl_expiry_reminder">
                        </b-col>
                        <b-col md="">
                            <span class="info_txt">Monitor URL needs to start with "https" for the SSL monitoring to work.</span>
                        </b-col>
                    </b-row>
                    <hr>
                    </div>
                    <div v-show="(monitor_input.type != 3 && monitor_input.type != 4 && monitor_input.type != 5 && monitor_input.type != 6 && monitor_input.type != 7)">
                    <b-row>
                        <b-col md="3" class="font-weight-bold">
                            HTTP Method
                        </b-col>
                        <b-col md="9">
                            <b-row v-b-toggle.post_reqest>
                                <b-col class="col-md-4">
                                    <span v-show="monitor_input.type != 2" :class="monitor_input.http_method == 'HEAD' ? 'mul-select-btn-active': 'mul-select-btn-inactive'"  @click="monitor_input.http_method = 'HEAD'">HEAD</span>
                                    <span :class="monitor_input.http_method == 'GET' ? 'mul-select-btn-active': 'mul-select-btn-inactive'" @click="monitor_input.http_method = 'GET'">GET</span>
                                    <span :class="monitor_input.http_method == 'POST' ? 'mul-select-btn-active': 'mul-select-btn-inactive'" @click="monitor_input.http_method = 'POST'">POST</span>
                                </b-col>
                                <b-col cols="1">
                                    <span class="info_txt">or</span>
                                </b-col>
                                <b-col class="mt-md-0 mt-2">
                                    <span :class="monitor_input.http_method == 'PUT' ? 'mul-select-btn-active': 'mul-select-btn-inactive'" @click="monitor_input.http_method = 'PUT'">PUT</span>
                                    <span :class="monitor_input.http_method == 'PATCH' ? 'mul-select-btn-active': 'mul-select-btn-inactive'" @click="monitor_input.http_method = 'PATCH'">PATCH</span>
                                    <span :class="monitor_input.http_method == 'DELETE' ? 'mul-select-btn-active': 'mul-select-btn-inactive'" @click="monitor_input.http_method = 'DELETE'">DELETE</span>
                                    <span :class="monitor_input.http_method == 'OPTIONS' ? 'mul-select-btn-active': 'mul-select-btn-inactive'" @click="monitor_input.http_method = 'OPTIONS'">OPTIONS</span>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>

                    <b-row v-show="monitor_input.http_method == 'POST' || monitor_input.http_method == 'PUT' || monitor_input.http_method == 'PATCH' || monitor_input.http_method == 'DELETE' || monitor_input.http_method == 'OPTIONS'">
                        <div class="col-md-12 p-4">
                            <b-row>
                                <!-- <b-col md="3"></b-col> -->
                                <b-col class="col-md-12">
                                   <b-row class="info_txt">
                                    <b-col>
                                        HEAD is suggested (as it is lighter) if there is no specific reason to user {{ monitor_input.http_method }}.
                                    </b-col>
                                    </b-row> 
                                    <b-row class="mt-2">
                                        <b-col>
                                            <span :class="post_data_type == 1 ? 'mul-select-btn-active': 'mul-select-btn-inactive'" @click="post_data_type = 1">ADD key/value</span>
                                            <span :class="post_data_type == 2 ? 'mul-select-btn-active': 'mul-select-btn-inactive'" @click="post_data_type = 2">Add raw data</span>
                                        </b-col> 
                                    </b-row>

                                    <div v-show="post_data_type == 1">
                                       <div class="font-weight-bold mt-3">Key / Value</div>
                                       <div>
                                        <b-button v-show="post_key_val_data.length <1" variant="primary" size="sm" @click="post_key_val_data.push({value: '',key:''})">Add Parameter</b-button>
                                        <div v-for="(v,i) in post_key_val_data" :key="i">
                                            <b-row class="mt-2">
                                                <b-col cols="5">
                                                    <b-input type="text" placeholder="key" v-model="v.key"  maxlength="2500"></b-input>
                                                </b-col>
                                                <b-col cols=5>
                                                    <b-input type="text" placeholder="value" v-model="v.value" maxlength="2500"></b-input>
                                                </b-col>
                                                <b-col style="cursor: pointer;" cols="2">
                                                    <b-row>
                                                        <b-col>
                                                            <!-- <i class="fa fa-plus-circle" title="add more"  @click="Clone()"></i> -->
                                                        <span  @click="post_key_val_data.push({value: '',key:''})" title="add more" class='cursor_pointer text-info'>Add</span> 
                                                        </b-col>
                                                        <b-col>
                                                            <!-- <i title="delete" class="fa fa-trash-o" @click="deltHeader(i)"></i> -->
                                                            <span @click="post_key_val_data.splice(i,1)" title="delete" class="cursor_pointer text-danger">Delete</span> 
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                            </b-row><hr>
                                        </div>
                                       </div>
                                    </div>
                                    
                                    <div v-show="post_data_type == 2">
                                       <div class="font-weight-bold mt-3">Raw Data</div>
                                       <div>
                                        <textarea class="form-control" name="row_data" id="row_data" rows="3" v-model="post_row_data" style="width: 100%;" placeholder='{"Key": "Value"}'></textarea>
                                        </div>
                                    </div>

                                    <div class="mt-3">
                                        <input class="checkbox_animated" name="postContentType" type="checkbox" v-model="postContentType">
                                        <span class="info_txt">
                                            Send as JSON (application/json). If not selected, the data will be sent as a standard POST (application/x-www-form-urlencoded).
                                        </span> 
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </b-row>
                      
                    <hr>
                    </div>
                    <div v-show="(monitor_input.type != 3 && monitor_input.type != 4 && monitor_input.type != 5 && monitor_input.type != 6 && monitor_input.type != 7)">

                    <b-row class="col-md-12">
                        <b-col md="12">
                            <span><span class="text-info f-20">Advanced Settings (Optional)</span></span>
                            <span v-b-toggle.advancesettings class="link_txt"><span> show/hide </span></span>
                            <hr>
                        </b-col>
                    </b-row>
                     <b-collapse id="advancesettings" role="tabpanel">
                            <b-row class="col-md-12">
                                <b-col md="12">
                                <span class="text-info">Authentication Settings </span>
                                <span v-b-toggle.authsetting class="link_txt">(show/hide)</span>
                                </b-col>
                            </b-row><hr>
                            <b-collapse id="authsetting" role="tabpanel">
                                <b-row class="col-md-12">
                                    <b-col class="font-weight-bold" md="3">
                                        Username
                                    </b-col>
                                    <b-col md="5">
                                        <b-form-input size="sm" type="text" v-model="monitor_input.auth_username" maxlength="50" :state="isUsernameEntered ? !invalid_username_message : null" @input="validate" placeholder="Username"></b-form-input>
                                        <b-form-invalid-feedback v-if="isUsernameEntered">{{ invalid_username_message }}</b-form-invalid-feedback>
                                    </b-col>
                                </b-row><hr>
                                <b-row class="col-md-12">
                                    <b-col class="font-weight-bold" md="3">
                                        Password
                                    </b-col>
                                    <b-col md="5">
                                        <b-form-input size="sm" type="text" v-model="monitor_input.auth_password" maxlength="25" :state="validate_passwordlimit" placeholder="Password"></b-form-input>
                                    </b-col>
                                </b-row><hr>
                                <b-row class="col-md-12">
                                    <b-col class="font-weight-bold" md="3">
                                        Authentication Type
                                    </b-col>
                                    <b-col md="5">
                                        <span :key=authKey>
                                        <span :class="monitor_input.auth_type == 1 ? 'mul-select-btn-active': 'mul-select-btn-inactive'"  @click="updateAuth(1)">HTTP Basic</span>
                                        <span :class="monitor_input.auth_type == 2 ? 'mul-select-btn-active': 'mul-select-btn-inactive'"  @click="updateAuth(2)">Digest</span>
                                        </span>
                                    </b-col>
                                </b-row><hr>
                            </b-collapse>
                            <div v-if="monitor_input.type == 1">
                            <b-row class="col-md-12">
                                <b-col md="12">
                                <span class="text-info">Custom Http Statuses </span>
                                <span v-b-toggle.customhttpstatus class="link_txt">(show/hide)</span>
                                </b-col>
                            </b-row><hr>
                        <b-collapse id="customhttpstatus" class="mt-1" role="tabpanel">
                            <b-row class="col-md-12">
                                <b-col>
                                    <p class="info_txt">You can drag and drop to modify <strong> <span class="text-success">Up</span>/<span class="text-danger">Down</span></strong>  statuses.</p>
                                </b-col>
                            </b-row>
                            <div class="drag-drop-vue" style="cursor:pointer;" title="click and drag">
                                <div
                                class="pull-left text-center"
                                v-dragula="monitor_input.up_status"
                                service="my-third"
                                drake="b"
                                >
                                <!-- <span style="color:white;">Up Status Code</span> -->
                                <div class="drag-btn-up" v-for="text in monitor_input.up_status" :key="text">
                                    <!-- <span class="dlt-status-btn" @click="CheckStatus(text,'up')" title="delete status"> X </span> -->
                                    <span class="p-2" @click="MoveStatus('right',text)">{{ text }}</span>
                                </div>
                                </div>
                                <div
                                class="pull-right text-center"
                                v-dragula="monitor_input.down_status"
                                service="my-third"
                                drake="b"
                                >
                                <!-- <span style="color:white;">Down Status Code</span> -->
                                <div class="drag-btn-down" v-for="text in monitor_input.down_status" :key="text">
                                    <!-- <span class="dlt-status-btn" @click="CheckStatus(text,'down')" title="delete status"> X </span> -->
                                    <span class="p-2" @click="MoveStatus('left',text)">{{ text }}</span>
                                </div>
                                </div>
                            </div>
                        </b-collapse>
                    </div>
                            <b-row class="col-md-12">
                                <b-col md="12">
                                <span class="text-info">Custom Headers </span>
                                <span v-b-toggle.customheaders  class="link_txt">(show/hide)</span>
                                </b-col>
                            </b-row><hr>
                            <b-collapse class="col-md-12" id="customheaders" role="tabpanel">
                                <p class="info_txt"> for example pass custom header parameters: <span class="link_txt">key and value</span></p>
                                <span v-if="(custom_headers.length<1)" @click="Clone()" style="cursor:pointer;">
                                    <b-row class="col-md-12">
                                    <h4><i class="fa fa-plus-circle" title="Add Header"></i></h4>
                                    <strong class="ml-2 mt-1">click here to add headers</strong>
                                </b-row>
                                </span>
                                
                                <div v-for="(v,i) in custom_headers" :key="i">
                                <b-row class="mt-2">
                                    <b-col cols="5">
                                        <b-input type="text" placeholder="key" v-model="v.key" @blur="validateHeaderKey(i)" maxlength="2500"></b-input>
                                        <span v-show="v.keyError" class="text-danger">{{ v.keyError }}</span>
                                    </b-col>
                                    <b-col cols=5>
                                        <b-input type="text" placeholder="value" v-model="v.value" @blur="validateHeaderValue(i)" maxlength="2500"></b-input>
                                        <span v-show="v.valueError" class="text-danger">{{ v.valueError }}</span>
                                    </b-col>
                                    <b-col style="cursor: pointer;" cols="2">
                                        <b-row>
                                            <b-col>
                                                <!-- <i class="fa fa-plus-circle" title="add more"  @click="Clone()"></i> -->
                                              <span  @click="Clone()" title="add more" class='cursor_pointer text-info'>Add</span> 
                                            </b-col>
                                            <b-col>
                                                <!-- <i title="delete" class="fa fa-trash-o" @click="deltHeader(i)"></i> -->
                                                <span @click="deltHeader(i)" title="delete" class="cursor_pointer text-danger">Delete</span> 
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row><hr>
                            </div>
                            </b-collapse>
                                <b-row class="col-md-12">
                                    <b-col md="12">
                                    <span class="text-info">Custom Proxy </span>
                                    <span v-b-toggle.customproxy  class="link_txt">(show/hide)</span>
                                    </b-col>
                                </b-row><hr>
                                <b-collapse class="col-md-12" id="customproxy" role="tabpanel">
                                    <b-row class="col-md-12">
                                        <b-col class="font-weight-bold" md="4">
                                            Custom Proxy URL or IP
                                        </b-col>
                                        <b-col md="12">
                                            <b-form-input size="sm" type="text" v-model="monitor_input.proxyUrl" maxlength="500" :state="validate_custom_proxyurl" ></b-form-input>
                                            <b-form-invalid-feedback :state="validate_custom_proxyurl">please enter valid proxy URL or IP</b-form-invalid-feedback>
                                        </b-col>
                                    </b-row><hr>
                                </b-collapse>
                            </b-collapse>
                            </div>
                            <div>
                                <b-row class="col-md-12">
                                    <b-col md="12">
                                        <span><span class="text-info f-20">Maintenance Window</span></span>
                                        <span v-b-toggle.maintenance class="link_txt"><span> show/hide </span></span>
                                       <hr>
                                    </b-col>
                                </b-row>
                                <b-collapse class="col-md-12" id="maintenance" role="tabpanel">
                                    <b-row class="col-md-12">
                                        <b-col class="font-weight-bold" md="4">
                                            Add Maintenance Window
                                        </b-col>
                                        <b-col md="12">
                                            <multiselect
                                            size="sm"
                                            v-model="monitor_input.mwids"
                                            placeholder="Select Maintenance"
                                            label="text"
                                            track-by="value"
                                            :show-labels="false"
                                            :options="maintenance_list"
                                            :multiple="true"
                                            :close-on-select="false"
                                            >

                                            <template #option="{ option }">
                                                <div class="d-flex justify-content-between">
                                                    <span>{{ option.text }}</span>
                                                    <span v-if="option.badge" class="badge badge-gray ml-2" style="font-size: 12px;">{{ option.badge }}</span>
                                                </div>
                                            </template>
                                            <template #selected="{ option }">
                                                <div class="d-flex justify-content-between">
                                                    <span>{{ option.text }}</span>
                                                    <span v-if="option.badge" class="badge badge-gray ml-2" style="font-size: 12px;">{{ option.badge }}</span>
                                                </div>
                                            </template>
                                            </multiselect>
                                        </b-col>
                                    </b-row><hr>
                                </b-collapse>
                            </div>
                    </div>
                    <b-row class="mt-3 text-center">
                        <b-col>
                            <b-button type="submit" variant="primary">{{edit_monitor?'Edit Monitor':'Save & Close'}}</b-button>
                            <b-button variant="primary" class="ml-2" v-if="!edit_monitor" @click="addMoreMonitor()">
                               + Add Another
                            </b-button>
                        </b-col>
                    </b-row>
                </div>
                </Transition>
                <!-- <div v-if="show_hertbeat_url">
                    <b-row>
                        <b-col class="text-center">
                            <h3 class="text-success font-weight-bold">Copy & Setup</h3>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="text-center">
                           <h4 class="text-info font-weight-bold"> The URL for the heartbeat monitor.</h4>
                        </b-col>
                    </b-row>
                        <b-row class="col-md-12">
                            <b-col class="hertbeat_url">
                                <div class="col-md-12">
                                    <span class="col-md-8 text-left"><code>{{hertbeat_url}}</code></span>
                                    <span class="col-md-4 text-right copy-btn" @click="copyUrl(hertbeat_url)">Copy</span>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row class="mt-2">
                            <b-col class="text-center">
                                <b-button variant="danger" @click="$emit('monitormodal')">
                                    Close
                                </b-button>
                                <b-button variant="primary" class="ml-2" v-if="!edit_monitor" @click="backToAddMonitor()">
                                + Add Another
                                </b-button>
                            </b-col>
                        </b-row>
                    </div> -->
                    <!-- Show HTTPS Monitor  End-->
        </form>
            </div>
        </div>
    </div>
</template>




<script>
 import VueSlideBar from "vue-slide-bar";
 import MonitorController from "../../services/monitorController"
 import { hertbeatUrl } from "../../services/config";
 import MaintenanceController from "../../services/maintenanceController";
 import Multiselect from 'vue-multiselect';
 import VueSlider from 'vue-slider-component';
 import moment, { invalid } from 'moment';
 export default {
    components:{ VueSlideBar, Multiselect, VueSlider },
    props:{add_monitor_modal:Boolean,edit_monitor:Boolean,monitor_data:Object},
    data(){
        return {
            show_SSL:true,
            authKey:1,
            sliderKey: 0, // Key attribute for force re-render
            grace_timeout_datas:[30,60,120,180,300,600,1200,1800,3600,7200,10800,18000,36000,54000,72000,86400],
            timeout_datas:[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60],
            interval_datas:[1, 2, 3, 5, 6, 10, 12, 15, 20, 30, 60, 120, 180, 240, 360, 480, 720, 1440],
            interval_datas_page_load:[60, 120, 180, 240, 360, 480, 720, 1440],
            interval_datas_screen_shot:[180, 240, 360, 480, 720, 1440],
            interval_datas_heartbeat_minutes:[1, 2, 3, 4, 5, 6, 10, 12, 15, 20, 30],
            interval_datas_heartbeat_hours:[1, 2, 3, 4, 6, 8, 12, 24],
            interval_datas_heartbeat_day:1,
            interval_opt:['Minute','Hour','Day'],
            interval_type:'Minute',
            grace_time_in:1,
            not_premium:false,
            custom_header_counter:0,
            custom_headers:[{value: '',key:''}],
            plan_details:{minIntervalLimit:5},
            alertContacts:[],
            alerts:[],
            hertbeat_url:'',
            hertbeat_id:'',
            show_hertbeat_url : false,
            sliderValue:50,
            width: 50,
            payload:{},
            show_custom_port:true,
            monitor_input:{
                name:'',
                type: null,
                url:'',
                interval:1,
                timeout:30,
                ssl_error:false,
                ssl_expiry_reminder:false,
                http_method:'GET',
                auth_type:1,
                up_status:[100,103,200,201,202,203,204,205,206,207,208,226,300,301,302,303,304,305,306,307,308],
                down_status:[400,401,402,403,404,405,406,407,408,409,410,411,412,413,414,415,416,417,418,421,422,423,424,425,426,428,429,430,431,440,449,450,451,495,496,497,499,500,501,502,503,504,505,508,509,510,511,520,521,522,523,524,525,526,527,530,598,599],
                custom_header:[],
                keyword:'',
                case_sensitive:false,
                alertWhen:1,
                port:null,
                custom_port:1,
                devicetype:null,
                alertContacts:[],
                downAlertFreqType:0,
                grace_interval:30,
                proxyUrl:'',
                postContentType:1,
                mwids:[],
            },
            device_type_opt:[
            { value: null, text:'Please Select' },
              { value: '1', text:'Desktop' },
              { value: '2', text:'Mobile' },
              { value: '3', text:'Tablet' },
            ],
            monitor_type_opt: [
              { value: null, text:'Please Select' },
              { value: '1', text:'HTTP(s)' },
              { value: '2', text:'Keyword' },
              { value: '3', text:'Ping' },
              { value: '4', text:'Port' },
              { value: '5', text:'Heartbeat' },
            //   { value: '6', text:'Page Speed (Paid)'},
            //   { value: '7', text:'Page Screenshot (Paid)'},
            ],
            alert_freq_opt: [
              { value: null, text:'select frequency' },
              { value: 0, text:'once' },
            ],
            port_opt:[
            { value: null, text:'Please Select' },
              { value: '80', text:'HTTP(80)' },
              { value: '443', text:'HTTPS(443)' },
              { value: '21', text:'FTP(21)' },
              { value: '25', text:'SMTP(25)' },
              { value: '110', text:'POP3(110)' },
              { value: '143', text:'IMAP(143)' },
              { value: '0', text:'Custom Port' }
            ],
            interval: {
                range: [
                {
                    label: "5 minutes",
                },
                {
                    label: "minutes",
                },
                ],
                data:[
                    5,10,15,20,25,30,35,40,45,50,55,60,120,180,240,300,360,420,480,540,600,660,720,780,840,900,960,1020,1080,1140,
                    1200,1260,1320,1380,1440
                ]
            },
            timeout: {
                range: [
                {
                    label: "1 minutes",
                },
                {
                    label: "minutes",
                },
                ],
            },
            invalid_fname_message: 'The name field is required',
            invalid_url_message:'The given URL/IP has an invalid character',
            invalid_keyword_message:'The keyword field is required',
            invalid_port_message: 'Please select a port',
            invalid_devicetype_message: 'Please select a device type',
            headerKeyPattern: /^[a-zA-Z0-9\-!#$%&'*+.^_`|~]+$/,
            headerValuePattern: /^[\x09\x20-\x7e\x80-\xff]*$/,
            maintenance_list:[],
            post_data_type:1,   
            post_key_val_data:[],   
            post_row_data:'',
            postContentType:false,
            isNameEntered: false,
            isUrlEntered: false,
            isKeywordEntered: false,
            isPortSelected: false,
            isDeviceTypeSelected: false,
            isUsernameEntered: false,
            invalid_username_message: 'The username field is required',
        }
    },
    mounted(){
        this.plan_details = this.$root.$data.plan_details
        if(this.plan_details.plan_type == '1'){
            this.not_premium = true
        }
        if(this.not_premium){
            this.monitor_input.interval = this.plan_details.minIntervalLimit
        }
        // this.monitor_input.interval = this.$root.$data.plan_details.minIntervalLimit
        this.getAlerts()
        this.getMantenance()
        if(this.edit_monitor){
            this.invalid_fname_message = '';
            this.isNameEntered = true;
            this.isPortSelected = true;
            this.invalid_port_message = '';
            this.isDeviceTypeSelected = true;
            this.invalid_devicetype_message = '';
            this.isUsernameEntered = true;
            this.monitor_input = this.monitor_data
            this.fillCustomPostData()
            if(this.monitor_input.up_status){this.monitor_input.up_status = this.monitor_input.up_status.sort()}
            if(this.monitor_input.down_status){this.monitor_input.down_status = this.monitor_input.down_status.sort()}
            if(!this.monitor_data.auth_type){this.monitor_input.auth_type = 1}
            this.hertbeat_id = this.monitor_input._id
            if(this.monitor_input.custom_header){
                this.custom_headers = []
            this.monitor_input.custom_header.map((v)=>{
                this.custom_headers.push({'key':Object.keys(v)[0],'value':Object.values(v)[0]}) 
            })
            }
            var result = this.port_opt.find(({value}) => value == this.monitor_input.port)
            this.monitor_input.custom_port = this.monitor_input.port
            if(!result){
                this.monitor_input.custom_port = this.monitor_input.port
                this.monitor_input.port = 0
            }
            this.validate_interval_type()
        }
    },
    computed:{
        validate_type(){
            if(!this.edit_monitor){
            if(this.monitor_input.type == 6){
                this.monitor_input.interval = 60
            }
            else if(this.monitor_input.type == 7){
                this.monitor_input.interval = 180
            }
            else{
                this.monitor_input.interval = this.plan_details.minIntervalLimit
            }
            }
            if(this.monitor_input.type == null)
            {
                return false;
            }
            else
            {
                return true;
            }
        },
        validate_port_state() {
            return this.invalid_port_message ? false : true;
        },
        validate_keyword() {
            if(this.monitor_input.keyword){
                this.isKeywordEntered = true;
            }
            if (this.monitor_input.type == 2 && this.monitor_input.keyword.trim() === '') {
                this.invalid_keyword_message = 'The keyword field is required';
            } else {
                this.invalid_keyword_message = '';
            }
        },
        validate_url(){
            if (this.monitor_input.url) {
                this.isUrlEntered = true;
                this.show_SSL = this.monitor_input.url.startsWith('https');
                if(!this.show_SSL){
                this.monitor_input.ssl_error = this.monitor_input.ssl_expiry_reminder = false
               }
            }
            return this.validation()
        },
        // validate_usernamelimit(){
        //     if(!this.Usernamevalidate()){
        //     return false;
        //     }

        //     return true;
        // },
        validate_passwordlimit(){
            if(this.monitor_input.auth_password && this.monitor_input.auth_password.length >25){
                this.$toasted.show('Characters Limit Exceeded!', {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
            }
        },
        validatePort(){
            if (this.monitor_input.custom_port < 1  ||  this.monitor_input.custom_port > 65535) {
                this.$toasted.show('Port must be between 1 and 65535.', {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
                this.monitor_input.custom_port = 1;
                return false;
            }
        },
        validate_custom_proxyurl(){
            if (this.validateUrl(this.monitor_input.proxyUrl)) {
                return true;
            }
            else{
                return false;}
        },
        inputPlaceholder() {
            if(this.monitor_input.type == 1 || this.monitor_input.type == 2 || this.monitor_input.type == 6 || this.monitor_input.type == 7){
                return 'URL or IP'
            } else {
                return 'Domain or IP'
            }
        }
        // interval_validate(){
        //     this.interval.range[1].label = this.monitor_input.interval + 'minutes'
        //     if(this.monitor_input.interval >= 60){
        //         this.interval.range[1].label = Math.round(this.monitor_input.interval /60,2) + 'hours'
        //     }
        // },
        // interval_timeout(){
        //     this.timeout.range[1].label = this.monitor_input.timeout + 'minutes'
        // }
    },
    methods: {
        changeDateFormate(d){
          return moment(d).format('D/M/YYYY, h:mm A')
        },
        async getMantenance(){
            let response = await MaintenanceController.getAll()
            if (response.result) {
                response.data.map(t => {
                    let badgeText = '';
                    switch (t.type) {
                        case 1:
                            badgeText = 'Once';
                            break;
                        case 2:
                            badgeText = 'Daily';
                            break;
                        case 3:
                            badgeText = 'Weekly';
                            break;
                        case 4:
                            badgeText = 'Monthly';
                            break;
                        default:
                            badgeText = '';
                    }
                    this.maintenance_list.push({ value: t._id, text: t.name, badge: badgeText });
                });
            }

            if (this.monitor_data.mwids && this.monitor_data.mwids.length > 0) {
                this.monitor_input.mwids = this.monitor_data.mwids
                    .map(t => this.maintenance_list.find(e => e.value === t))
                    .filter(id => id);
                }
        },
        updateAuth(val){
            this.monitor_input.auth_type = val
            this.authKey ++
        },
        MoveStatus(txt,value){
            switch(txt){
                case 'right':
                    this.monitor_input.down_status.push(value)
                    const index = this.monitor_input.up_status.indexOf(value);
                    if (index > -1) { // only splice array when item is found
                        this.monitor_input.up_status.splice(index, 1); // 2nd parameter means remove one item only
                    }
                    break;
                case 'left':
                    this.monitor_input.up_status.push(value)
                    const indx = this.monitor_input.down_status.indexOf(value);
                    if (indx > -1) { // only splice array when item is found
                            this.monitor_input.down_status.splice(indx, 1); // 2nd parameter means remove one item only
                    }
                    break;
            }
        },
        Clone(){
            this.custom_headers.push({value: '',key:''})
        },
        deltHeader(i){
            this.custom_headers.splice(i,1)
        },
        async getAlerts(){
            let response= await MonitorController.getAlerts()
            if (response.data) {
                response.data.map((t) => {
                    if (t.status) {
                        let iconClass = '';
                        switch (t.type) {
                            case 1:
                                iconClass = 'icofont icofont-envelope'; // Email
                                break;
                            case 2:
                                iconClass = 'icofont icofont-social-skype'; // Skype
                                break;
                            case 3:
                                iconClass = 'icofont icofont-social-telegram'; // Telegram
                                break;
                            case 4:
                                iconClass = 'icofont icofont-web'; // Webpush
                                break;
                            case 5:
                                iconClass = 'icofont icofont-globe-alt'; // Webhook
                                break;
                            case 6:
                                iconClass = 'icofont icofont-ui-messaging'; // Message
                                break;
                            case 7:
                                iconClass = 'icofont icofont-phone'; // Call
                                break;
                            case 8:
                                iconClass = 'icofont icofont-social-slack'; // Slack
                                break;
                            case 9:
                                iconClass = 'icofont icofont-wheel'; // Zappier
                                break;
                            default:
                                iconClass = ''; 
                        }
                        this.alerts.push({ value: t._id, text: t.name, icon: iconClass });
                    }
                });
            }
            if(this.edit_monitor){
            this.alertContacts = this.alerts.filter(e=> this.monitor_input.alertContacts.includes(e.value))}
         },
        addTag (newTag) {
            const tag = {
                name: newTag,
                code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
            }
            this.options.push(tag)
            this.value.push(tag)
        },
        validate_port() {
            this.isPortSelected = true;
            if (!this.monitor_input.port) {
                this.invalid_port_message = 'Please select a port';
            } else {
                this.invalid_port_message = '';
            }

            this.show_custom_port = false;
            if (this.monitor_input.port != 0) {
                this.monitor_input.custom_port = this.monitor_input.port;
                this.show_custom_port = true;
            }
        },
        validate_deviceType() {
            this.isDeviceTypeSelected = true;
            if (!this.monitor_input.devicetype) {
                this.invalid_devicetype_message = 'Please select a device type';
            } else {
                this.invalid_devicetype_message = '';
            }
        },
        validateUrlold(str){
             // discontinue because it's not validate all types of url's
             // http://205.147.109.80:18899/diskspace it's not validate this type of url
            if(str){
            // var res = str.match(/^((http(s)?):\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/);
            // var res = str.match(/^((http(s)?):\/\/)?([a-z0-9]+:[a-z0-9]+@)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/);
            // added url also acccept IP url's as dicussed
            var res = str.match(/^((http(s)?):\/\/)?(([a-z0-9]+:[a-z0-9]+@)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(\/.*)?$/);
            if(res == null){
                return false;
            }else{
            if(this.monitor_input.type == '1' || this.monitor_input.type == '2' || this.monitor_input.type == '6' || this.monitor_input.type == '7'){
            var isMatch = str.substr(0, 8) == 'https://' || str.substr(0, 7) == 'http://';
            if(isMatch){
                return true;
            }
            else{
                return false;
            }}
            return true;
            }
            }
        },
        validateUrl(str) {

            if (!str) {
                return false;
            }
            if (str.includes(' ')) {
                return false;
            }
            try {
                // Attempt to create a new URL object
                let x = new URL(str);
                if(!(x.protocol == 'http:' || x.protocol == 'https:')) {
                    return false 
                } 

                let pos=(x+"").indexOf(str);
                if(pos===0) return true;

                return false; // If no error was thrown, the URL is valid
            } catch (error) {
                return false;
            }
        },
        validateIp(ipaddress){
            if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {  
                return true;
            } 
            return false;
        },
        validateDomain(domain){
            var regexp = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;
            if (regexp.test(domain)){ return true; }
            else{ return false; }
        },
        validateName(name){
            this.isNameEntered = true;
            name = name ? name : this.monitor_input.name

            let regexp = /^(?!\s*$)[a-zA-Z0-9 ._-]+$/;
            if (regexp.test(name)) {
                this.invalid_fname_message = false;
                return true;
            } 
            else{ 
                this.invalid_fname_message = 'Please enter valid friendly name';
                return false; 
            }
        },
        validatePostRowData(data) {
            try { 
                data = JSON.parse(data)
                if(typeof data === 'object' && !Array.isArray(data)){
                    return true
                }
            } catch (r) {
                return false
            }
            return false
        },
        CheckStatus(text,action){
            if(action == 'up'){
                const id = this.monitor_input.up_status.indexOf(text); // 2
                const removeArray = this.monitor_input.up_status.splice(id,  1);
            // this.monitor_input.up_status = this.monitor_input.up_status.filter(function(item) {
            //     return item !== text
            // })
            }
            if(action == 'down'){
                const id = this.monitor_input.down_status.indexOf(text); // 2
                const removeArray = this.monitor_input.down_status.splice(id,  1);
            // this.monitor_input.down_status = this.monitor_input.down_status.filter(function(item) {
            //     return item !== text
            // })
            }
        },
        async onSubmit(txt){
            if(this.validate()){
            this.payload={}
            this.monitor_input.alertContacts=[]
            this.alertContacts.map((l)=>{
                this.monitor_input.alertContacts.push(l.value)
            })
            this.monitor_input.custom_header = []
            var c_headers = []
            for(let i=0; i<this.custom_headers.length; i++) {
                if((this.custom_headers[i]["key"] && this.custom_headers[i]["value"]) !='')
                c_headers.push({ [this.custom_headers[i]["key"]] : this.custom_headers[i]["value"]})
                }
            this.monitor_input.custom_header = c_headers
            switch(this.monitor_input.type.toString()){
                case '1':
                    this.payload = {"name": this.monitor_input.name,"type": this.monitor_input.type,"url": this.monitor_input.url,"interval": this.monitor_input.interval,"timeout": this.monitor_input.timeout,"ssl_error": this.monitor_input.ssl_error,"ssl_expiry_reminder": this.monitor_input.ssl_expiry_reminder,"auth_type": this.monitor_input.auth_type,"auth_username": this.monitor_input.auth_username,"auth_password": this.monitor_input.auth_password,"http_method": this.monitor_input.http_method,"up_status": this.monitor_input.up_status,"down_status": this.monitor_input.down_status,"custom_header":this.monitor_input.custom_header,"alertContacts":this.monitor_input.alertContacts,"downAlertFreqType":this.monitor_input.downAlertFreqType,'proxyUrl':this.monitor_input.proxyUrl}
                    this.checkCustomPostData()
                    break;
                case '2':
                    this.payload = {"name": this.monitor_input.name,"type": this.monitor_input.type,"url": this.monitor_input.url,"interval": this.monitor_input.interval,"timeout": this.monitor_input.timeout,"ssl_error": this.monitor_input.ssl_error,"ssl_expiry_reminder": this.monitor_input.ssl_expiry_reminder,"auth_type": this.monitor_input.auth_type,"auth_username": this.monitor_input.auth_username,"auth_password": this.monitor_input.auth_password,"http_method": this.monitor_input.http_method,"alertWhen":this.monitor_input.alertWhen,"case_sensitive": this.monitor_input.case_sensitive,"keyword": this.monitor_input.keyword,"custom_header":this.monitor_input.custom_header,"alertContacts":this.monitor_input.alertContacts,"downAlertFreqType":this.monitor_input.downAlertFreqType,'proxyUrl':this.monitor_input.proxyUrl}
                    this.checkCustomPostData()
                    break;
                case '3':
                    this.payload = {"name": this.monitor_input.name,"type": this.monitor_input.type,"url": this.monitor_input.url,"interval": this.monitor_input.interval,"alertContacts":this.monitor_input.alertContacts,"downAlertFreqType":this.monitor_input.downAlertFreqType,}
                    break;
                case '4':
                    var port = this.monitor_input.port
                    if(this.monitor_input.port == 0){
                        port = this.monitor_input.custom_port
                    }
                    this.payload = {"name": this.monitor_input.name,"type": this.monitor_input.type,"url": this.monitor_input.url,
                    "port":port,"interval": this.monitor_input.interval,"timeout": this.monitor_input.timeout,"alertContacts":this.monitor_input.alertContacts,"downAlertFreqType":this.monitor_input.downAlertFreqType,}
                    break;
                case '5': //Heartbeat payload
                    this.payload = {"name" : this.monitor_input.name, "type": this.monitor_input.type, "interval": this.monitor_input.interval,"alertContacts":this.monitor_input.alertContacts,"downAlertFreqType":this.monitor_input.downAlertFreqType,"grace_interval":this.monitor_input.grace_interval}
                    switch(this.interval_type){
                        case 'Minute':
                            this.payload = {"name" : this.monitor_input.name, "type": this.monitor_input.type, "interval": this.monitor_input.interval,"dayCount":0,"alertContacts":this.monitor_input.alertContacts,"downAlertFreqType":this.monitor_input.downAlertFreqType,"grace_interval":this.monitor_input.grace_interval}
                            break;
                        case 'Hour':
                            let interval_hour = this.monitor_input.interval * 60
                            this.payload = {"name" : this.monitor_input.name, "type": this.monitor_input.type, "interval": interval_hour,"dayCount":0,"alertContacts":this.monitor_input.alertContacts,"downAlertFreqType":this.monitor_input.downAlertFreqType,"grace_interval":this.monitor_input.grace_interval}
                            break;
                        case 'Day':
                            let interval_minutes = 24 * 60
                            this.payload = {"name" : this.monitor_input.name, "type": this.monitor_input.type, "interval": interval_minutes,"dayCount":this.monitor_input.dayCount,"alertContacts":this.monitor_input.alertContacts,"downAlertFreqType":this.monitor_input.downAlertFreqType,"grace_interval":this.monitor_input.grace_interval}
                            break;
                        }
                    break;
                case '6':
                    this.payload = {"type": this.monitor_input.type,"name" : this.monitor_input.name, "interval": this.monitor_input.interval,"url": this.monitor_input.url,"alertContacts":this.monitor_input.alertContacts,"downAlertFreqType":this.monitor_input.downAlertFreqType,"timeout": this.monitor_input.timeout}
                    break;
                case '7':
                    this.payload = {"type": this.monitor_input.type,"name" : this.monitor_input.name, "interval": this.monitor_input.interval,"timeout": this.monitor_input.timeout,"url": this.monitor_input.url,'devicetype':this.monitor_input.devicetype,"alertContacts":this.monitor_input.alertContacts,"downAlertFreqType":this.monitor_input.downAlertFreqType,}
                break;
            }
            this.payload.mwids = []
            if(this.monitor_input.mwids.length > 0){
                this.monitor_input.mwids.map((t)=>{
                    this.payload.mwids.push(t.value)
                })
            }
            if(Object.keys(this.payload).length > 0){
                var response
                if(this.edit_monitor){
                    var p = { "mid": this.monitor_input._id, "changePayload":this.payload }
                    response= await MonitorController.editMonitor(p)
                }
                else{
                    response= await MonitorController.createMonitor(this.payload)
                    if(response.result){
                        if(this.monitor_input.type == 5){
                            this.hertbeat_id = response.data.mid
                        } 
                    }
                }
                if(response.result){
                    this.show_hertbeat_url = false; 
                // if(this.monitor_input.type == 5){
                //     this.monitor_input.type = null;
                //     this.hertbeat_url = hertbeatUrl+ this.hertbeat_id
                //     this.show_hertbeat_url = true;
                // }
                // else{
                    if(!txt) {
                        this.$emit('monitormodal')
                    }
                
                this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 3000});
                }
                else{
                    this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
                }
            }
        }
        // this.disable_submit = false
        },
        fillCustomPostData(){
            this.post_data_type = this.monitor_data.postType
            if(this.monitor_data.postType == 1){
                let data = JSON.parse(this.monitor_data.postValue)
                this.post_key_val_data = Object.keys(data).map(key => ({ key, value: data[key] }));
            }
            else{
                this.post_row_data = this.monitor_data.postValue
            }
            if(this.monitor_data.postContentType == 2){
                this.postContentType = true
            }
            else{
                this.postContentType = false
            }
        },
        checkCustomPostData(){
            if(this.monitor_input.http_method == 'POST' || this.monitor_input.http_method == 'PUT' || this.monitor_input.http_method == 'PATCH' || this.monitor_input.http_method == 'DELETE' || this.monitor_input.http_method == 'OPTIONS'){
                if(this.postContentType){
                    this.payload['postContentType'] = 2
                }
                else{
                    this.payload['postContentType'] = 1
                }
                if(this.post_data_type == 1){
                    let data = this.post_key_val_data.reduce((acc, { key, value }) => {
                                                acc[key] = value;
                                                return acc;
                                                }, {});
                    this.payload['postValue'] = JSON.stringify(data)
                }
                else{
                    this.payload['postValue'] = this.post_row_data
                }
                this.payload['postType'] = this.post_data_type
            }
        },
        copyUrl(txt){
          const el = document.createElement('textarea');
          el.addEventListener('focusin', e => e.stopPropagation());
          el.value = txt;
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          document.body.removeChild(el);
          this.$toasted.show('URL copied to clipboard.', {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
        },
        validate(){
            let nameValidate = this.validateName(this.monitor_input.name)
            let urlValidate = this.validation() || this.monitor_input.type == 5
            
            if(urlValidate && nameValidate) {
                return true
            } else if(!nameValidate) {
                this.$toasted.show(this.invalid_fname_message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
                return false
            } else if(!urlValidate) {
                this.$toasted.show(this.invalid_url_message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
                return false
            } else {
                this.$toasted.show('Please fill all required fields', {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
                return false
            }
        },
        validation(){
            if(this.monitor_input.type == '1' || this.monitor_input.type == '2' || this.monitor_input.type == '6' || this.monitor_input.type == '7'){
                this.invalid_url_message = 'Please enter valid URL with http:// or https://'
            
                let urlValidate = this.validateUrl(this.monitor_input.url)
                let proxyUrlValidate = this.monitor_input.proxyUrl ? this.validateUrl(this.monitor_input.proxyUrl) : true
                let validRowData = this.validatePostRowData(this.post_row_data)
                if (urlValidate && proxyUrlValidate) {
                    if (this.post_row_data) {
                        if (validRowData) {
                            this.payload['postValue'] = this.post_row_data;
                            return true
                        } else {
                            this.invalid_url_message = 'Please enter valid Row data'
                            return false
                        }
                    } 
                    this.invalid_url_message =''
                    return true;
                } else {
                    return false;
                }
            }
            else if(this.monitor_input.type == '3' || this.monitor_input.type == '4'){
                this.invalid_url_message = 'Please enter valid domain or IP address'

                if (this.validateIp(this.monitor_input.url) || this.validateDomain(this.monitor_input.url)) {
                    this.invalid_url_message = ''
                    return true;
                } else {
                    return false;
                }
            }
            else if(this.monitor_input.type == '5'){
                return true;
            }
            else{
                if (this.validateIp(this.monitor_input.url) || this.validateDomain(this.monitor_input.url) || this.validateUrl(this.monitor_input.url)) {
        
                    return true;
                } else {
                    return false;
                }
            }
        },
        checkIntervallimit(){
            if(this.not_premium && this.monitor_input.interval <5){
                this.monitor_input.interval = this.plan_details.minIntervalLimit
                var el = document.querySelector('#upgrade_btn');
                el.click()
            }
            this.sliderKey += 1;
        },
        fancyTimeFormat(duration){   
            var hrs = ~~(duration / 3600);
            var mins = ~~((duration % 3600) / 60);
            var secs = ~~duration % 60;
            var ret = "";
            if (hrs > 0) {
                ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
            }
            ret += "" + mins + ":" + (secs < 10 ? "0" : "");
            ret += "" + secs;
            return ret;
        },
        validate_interval_type(){
            if(this.monitor_input.dayCount && this.monitor_input.dayCount>= 0){
                this.interval_type = 'Day'
            }
            else if(this.monitor_input.interval >30 && this.monitor_input.type == 5){
                this.interval_type = 'Hour'
                this.monitor_input.interval = this.monitor_input.interval / 60
            }
        },
        validateHeaderKey(index) {
            if (!this.headerKeyPattern.test(this.custom_headers[index].key)) {
            this.custom_headers[index].keyError = "Invalid header key";
            this.custom_headers[index].key =''
            } else {
            this.custom_headers[index].keyError = "";
            }
        },
        validateHeaderValue(index) {
            if (!this.headerValuePattern.test(this.custom_headers[index].value)) {
            this.custom_headers[index].valueError = "Invalid header value";
            this.custom_headers[index].value =''
            } else {
            this.custom_headers[index].valueError = "";
            }
        },
        validateUsername() {
        this.isUsernameEntered = true;
        let username = this.monitor_input.auth_username ? this.monitor_input.auth_username.trim() : "";

        const regex = /^[a-zA-Z0-9 ]+$/; // Alphanumeric and space characters only
        const maxLength = 50; // Maximum length of the full name field
        if (username === "") {
            this.invalid_username_message = "Username should not be empty"
            return false;
        }else if (username && username.length >= maxLength) {
          this.invalid_username_message = "Username cannot exceed "+maxLength+" characters"
          return true;
        }
        else if (!regex.test(username) && username != '') {
          this.invalid_username_message = "Username can only contain alphanumeric characters and spaces"
          return false;
        } else {
          this.invalid_username_message = "";
          return true;
        }
      },
      addMoreMonitor() {
        this.onSubmit("addMore")
        this.monitor_input.name = ''
        this.monitor_input.url = ''
      }
    }
 }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.copy-btn{
    padding: 5px;
    background: white;
    color: black;
    border-radius: 10px;   
    cursor: pointer;
}
.hertbeat_url{
    background: #000;
    color: white !important;
    padding: 10px;
    border-radius: 10px;
}
.drag-drop-vue div:nth-child(odd){
    background-color: #bcffbb3d;
    border: none;
    border-radius: 8px;
    padding:5px;
}
.drag-drop-vue div {
    background-color: #ffe0e08a;
    border: none;
    border-radius: 8px;
    padding:5px;
    float: left;
}
hr {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.info_txt{
    font-size:11px;
    color: gray;
}
.mul-select-btn-inactive{
    padding: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    margin: 2px;
    cursor: pointer;
    background: white;
    color: black;
    font-weight: 700;
}
.mul-select-btn-active{
    padding: 5px;
    border: 1px solid rgba(13, 177, 4, 0.1);
    border-radius: 2px;
    margin: 2px;
    cursor: pointer;
    background: #3b9b28;
    color: white;
    font-weight: 700;
}
.drag-btn-up{
    text-align: center !important;
    background: green !important;
    font-weight: 700;
    max-width: 60px;
    float: right;
    border-radius: 8px;
    margin: 5px;

}
.drag-btn-down{
    text-align: center !important;
    background: rgb(164, 13, 13) !important;
    color: white !important;
    font-weight: 700;
    max-width: 60px;
    float: right;
    border-radius: 8px;
    margin: 5px;

}
.dlt-status-btn{
    float: left !important;
    cursor: pointer;
}
.outer, .inner {
  /* background: #eee; */
  /* padding: 10px; */
  min-height: 100px;
}
  
.inner { 
  /* background: rgb(225, 224, 224); */
  /* padding: 10px; */
}
  
.nested-enter-active, .nested-leave-active {
	transition: all 0.3s ease-in-out;
}
/* delay leave of parent element */
.nested-leave-active {
  transition-delay: 0.25s;
}

.nested-enter-from,
.nested-leave-to {
  transform: translateY(30px);
  opacity: 0;
}

/* we can also transition nested elements using nested selectors */
.nested-enter-active .inner,
.nested-leave-active .inner { 
  transition: all 0.3s ease-in-out;
}
/* delay enter of nested element */
.nested-enter-active .inner {
	transition-delay: 0.25s;
}

.nested-enter-from .inner,
.nested-leave-to .inner {
  transform: translateX(30px);
  /*
  	Hack around a Chrome 96 bug in handling nested opacity transitions.
    This is not needed in other browsers or Chrome 99+ where the bug
    has been fixed.
  */
  opacity: 0.001;
}

.box{
  width: 50%;
  height:100px;
  background:#000;
  color:#fff;
  font-size: 2em;
  text-align:center;
  padding-top: 50px;
  font-family:'Arial';
  font-weight: bold;
}
/* The slider itself */
.slider {
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    height: 25px; /* Specified height */
    background: #d3d3d3; /* Grey background */
    outline: none; /* Remove outline */
    opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    width: 100%;
}

/* Mouse-over effects */
.slider:hover {
    opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */ 
.slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 15px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #1f9022; /* Green background */
    cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #1f9022; /* Green background */
    cursor: pointer; /* Cursor on hover */
}
.vue-slider{
    padding: 5px !important;
    margin: 10px;
    
}
</style>