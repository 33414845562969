<template>
  <div>
    <b-row class="mr-1">
      <b-col class="showBreadcrumbs d-flex align-items-center justify">
        <Breadcrumbs main="" title="Monitor" class=""/>
        <button class="showMonitorBtn col-md-3 btn custom-font-20 add-monitor-btn-green" @click="add_monitor_modal = true" >+ Add New Monitor</button>
      </b-col>
    </b-row>

    <b-row class="sectionShowDesktop">
      <b-col class="ml-2 col-md-2" >
        <b-row>
          <b-col class="input-group cus-search-input">
            <div style="height: 60px;"></div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="input-group cus-search-input mb-2">
            <div class="form-group input-group-square">
              <b-input-group >
                <b-input-group-prepend >
                  <span class="custom-font-22 input-group-text search-bg border-right-0"><i class="icofont icofont-search-alt-2"></i></span>
                </b-input-group-prepend>
                <b-form-input
                  class="custom-font-22 border-left-0"
                  v-model="filter"
                  placeholder="Search"
                  type="search"
                ></b-form-input>
              </b-input-group>
            </div>
          </b-col>
        </b-row>
      </b-col>

      <b-col md="3">
        <b-row class="text-right">
          <b-col style="height: 60px;" class="pl-0">
            <button class="btn custom-font-22 custom-btn-dark" @click="open_import_monitor = true">Import Monitors</button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-select
                  class="custom-border-06 b-r-8 custom-font-22 gray-font"
                  v-model="monitorType"
                  :options="monitor_type_opt"
                  @change="filterMonitorByType(monitorType)"
                ></b-form-select>
          </b-col>
        </b-row>
      </b-col>

      <b-col md="4" class="ml-3">
        <b-row>
          <b-col class="text-left pl-0" style="height: 60px;">
            <button class="btn custom-font-22 custom-btn-dark" @click="ExportMonitor()">Export Monitors</button>
          </b-col>
          <b-col class="text-right pl-0 pr-0">
            <button class="btn col custom-font-22 custom-btn-dark" @click="open_bulk_action = true">Bulk Actions</button>
          </b-col>
        </b-row>
        <b-row>
          <b-col >
            <div class="row btn-group-square custom-border-06 b-r-8 p-1">
              <button class="col btn custom-btn" :class="[activeButton === 'all' ? 'btn-dark' : 'text-dark']" @click="toggleActive('all')">All</button>
              <button class="col btn custom-btn" :class="[activeButton === 'up' ? 'up-bg' : 'text-success']" @click="toggleActive('up')">Up</button>
              <button class="col btn custom-btn" :class="[activeButton === 'down' ? 'down-bg' : 'text-danger']" @click="toggleActive('down')">Down</button>
              <button class="col btn custom-btn" :class="[activeButton === 'pause' ? 'pause-bg' : 'text-secondry']" @click="toggleActive('pause')">Pause</button>
            </div>
          </b-col>
        </b-row>
      </b-col>

      <b-col class="text-right mr-2">
        <b-row>
          <b-col style="height: 60px;" class="hideMonitorBtn">
            <button class="btn custom-font-22 add-monitor-btn-green-1" @click="add_monitor_modal = true" >+ Add New Monitor</button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
            label-cols="6"
            label="Per page"
            class="text-right custom-font-20 text-secondary border border-dark pr-0 pl-0 b-r-8 d-inline-block"
          >
            <b-form-select
              class="custom-font-22 text-dark border-0 b-r-8"
              v-model="perPage"
              :options="pageOptions"
              @change="setPerpage"
              >
            </b-form-select>
          </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="m-0 sectionShowMobile">
      <b-col class="d-flex justify-content-between">
        <button class="btn mr-1 mt-1 custom-font-20 custom-btn-dark" @click="open_import_monitor = true">Import Monitors</button>
        <button class="btn mr-1 mt-1 custom-font-20 custom-btn-dark" @click="ExportMonitor()">Export Monitors</button>
        <button class="btn mt-1 custom-font-20 custom-btn-dark" @click="open_bulk_action = true">Bulk Actions</button>
      </b-col>
      <b-col class="input-group mt-3 cus-search-input form-group input-group-square">
        <b-input-group >
          <b-input-group-prepend >
            <span class="custom-font-22 input-group-text search-bg border-right-0"><i class="icofont icofont-search-alt-2"></i></span>
          </b-input-group-prepend>
          <b-form-input
            class="custom-font-20 border-left-0"
            v-model="filter"
            placeholder="Search"
            type="search"
          ></b-form-input>
        </b-input-group>
      </b-col>
    </b-row>
    
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
            <div slot="with-padding">

              <!-- <b-row class="mt-1 cust-monitor-btn-cont sectionShowDesktop">
                <b-col class="input-group mt-1 cus-search-input mb-2">
                  <div class="form-group input-group-square">
                    <b-input-group >
                      <b-input-group-prepend >
                        <span class="custom-font-22 input-group-text search-bg border-right-0"><i class="icofont icofont-search-alt-2"></i></span>
                      </b-input-group-prepend>
                      <b-form-input
                        class="custom-font-22 border-left-0 pr-4"
                        v-model="filter"
                        placeholder="Type to Search"
                      ></b-form-input>
                    </b-input-group>
                  </div>
                </b-col>

                <b-col  class="mt-md-0 cus-btn-group text-right">
                  <b-form-select
                    class="custom-border-06 b-r-8 custom-font-22 gray-font mt-1"
                    v-model="monitorType"
                    :options="monitor_type_opt"
                    @change="filterMonitorByType(monitorType)"
                  ></b-form-select>
                </b-col>

                <b-col class="text-right mt-1">
                  <b-button-group class="btn-group-square custom-border-06 b-r-8 p-1">
                    <button class="btn custom-btn" :class="[activeButton === 'all' ? 'btn-dark' : 'text-dark']" @click="toggleActive('all')">All</button>
                    <button class="btn custom-btn" :class="[activeButton === 'up' ? 'up-bg' : 'text-success']" @click="toggleActive('up')">Up</button>
                    <button class="btn custom-btn" :class="[activeButton === 'down' ? 'down-bg' : 'text-danger']" @click="toggleActive('down')">Down</button>
                    <button class="btn custom-btn" :class="[activeButton === 'pause' ? 'pause-bg' : 'text-secondry']" @click="toggleActive('pause')">Pause</button>
                  </b-button-group>
                  
                </b-col>
                <b-col class="col-md-2 text-right mt-1">
                  <b-form-group
                    label-cols="6"
                    label="Per page"
                    class="text-right custom-font-20 text-secondary border border-dark pr-0 pl-0 b-r-8 d-inline-block"
                    >
                    <b-form-select
                      class="custom-font-22 text-dark border-0 b-r-8"
                      v-model="perPage"
                      :options="pageOptions"
                      @change="setPerpage"
                      >
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row> -->

              <b-row class="mt-3 cust-monitor-btn-cont sectionShowMobile">
                <b-col md="6" class="mt-2 mt-md-0 cus-btn-group">
                  <b-button-group class="btn-group-square custom-border-06 b-r-8 p-1">
                    <button class="btn custom-btn" :class="[activeButton === 'all' ? 'btn-dark' : 'text-dark']" @click="toggleActive('all')">All</button>
                    <button class="btn custom-btn" :class="[activeButton === 'up' ? 'up-bg' : 'text-success']" @click="toggleActive('up')">Up</button>
                    <button class="btn custom-btn" :class="[activeButton === 'down' ? 'down-bg' : 'text-danger']" @click="toggleActive('down')">Down</button>
                    <button class="btn custom-btn" :class="[activeButton === 'pause' ? 'pause-bg' : 'text-secondry']" @click="toggleActive('pause')">Pause</button>
                  </b-button-group>
                </b-col>
                <b-col md="6">
                  <b-row >
                    <b-col >
                      <b-form-select
                          class="custom-border-06 b-r-8 custom-font-22 gray-font"
                          v-model="monitorType"
                          :options="monitor_type_opt"
                          @change="filterMonitorByType(monitorType)"
                        ></b-form-select>
                    </b-col>
                    <b-col class="text-right">
                      <b-form-group
                        label-cols="6"
                        label="Per page"
                        class="text-right custom-font-20 text-secondary border border-dark pr-0 pl-0 b-r-8 d-inline-block"
                        >
                        <b-form-select
                          class="custom-font-22 text-dark border-0 b-r-8"
                          v-model="perPage"
                          :options="pageOptions"
                          @change="setPerpage"
                          >
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <div class="col-md-12" v-if="loading">
                <h6 class="sub-title mb-0 text-center">Loading...</h6>
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
              </div> 
              <div v-else class="table-responsive datatable-vue text-left custom-font-18" style="overflow: hidden;">
                <b-table
                  show-empty
                  stacked="md"
                  :items="items"
                  :fields="tablefields"
                  :filter="filter"
                  :per-page="perPage"
                  @filtered="onFiltered"
                >
                <!-- <template #table-caption>Monitor List.</template> -->

                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(name)="data">
                  <div class="cursor_pointer cust_txt" title="click to check incident" @click="openIncident(data.item)">
                  <span v-if="!data.item.status" :class="!data.item.status?'text-secondary':'bg-white'">{{ data.item.name }}</span> 
                  <span v-else :class="data.item.isDown?'text-danger':'text-success'">{{ data.item.name }}</span>
                  </div>
                  <!-- <div class="row">
                  <div class="col-md-8"> -->
                    <!-- <span v-if="data.item.type == 5" class="">
                    <span v-b-toggle=data.item._id  size="sm" class="f-w-900">{{ data.item.name }} -->
                      <!-- <i class="ml-2 icofont icofont-arrow-down"></i> -->
                    <!-- </span> -->
                      <!-- <b-collapse :id="data.item._id" role="tabpanel">
                      <b-card-body>
                        <b-card-text><code>{{heartbeat_url+ data.item._id}}</code></b-card-text>
                        <b-card-text class="link_txt"  @click="CopyHearbeatUrl(heartbeat_url+ data.item._id)">Copy</b-card-text>
                      </b-card-body>
                      </b-collapse> -->
                    <!-- </span>
                    <span v-else> -->
                      <!-- <b class="">{{ data.item.name }}</b> -->
                    <!-- </span> -->
                  <!-- </div> -->
                  <!-- <div class="col-md-4 float-right">
                    <div class="f-16" style="cursor: pointer;">
                      <span v-if="!data.item.status" title="Pause">&#128993;</span>
                      <span v-else-if="data.item.isDown" title="Down">&#128308;</span>
                      <span v-else title="Up">&#128994;</span>
                    </div>
                    <div class="f-w-900 text-center f-12">{{ monitor_type_opt[data.item.type] }}</div>
                  </div> -->
                   <!-- </div> -->
                </template>
                
                <template #cell(type)="data">
                  <span v-if="monitor_type_opt.filter(t=> t.value == data.item.type).length>0">{{ monitor_type_opt.filter(t=> t.value == data.item.type)[0].text }}</span>
                </template>

                <template #cell(interval)="data">
                  <span v-if="data.item.dayCount && data.item.dayCount > 1">
                    {{ data.item.dayCount }} Days
                  </span>
                  <span v-else>{{ convertMsToHM(data.item.interval) }}</span>
                </template>
                <!-- <template #cell(createdAt)="data">
                  {{ new Date(data.item.createdAt).toLocaleDateString() }} - {{new Date(data.item.createdAt).toTimeString().substring(0, new Date(data.item.createdAt).toTimeString().indexOf("GMT"))}}
                </template> -->

                <template #cell(status)="data">
                    <div class="media-body switch-outline">
                        <label class="switch">
                        <input type="checkbox" :checked=data.item.status @change="statusUpdate(data.item)">
                        <span class="switch-state" :class="data.item.status?'bg-success':'bg-danger'"></span>
                        </label>
                    </div>                 
                </template>
                <template #cell(url)="data">
                  <div class="res_text text-center copy_cursor" title="click to copy">
                    <span v-if="data.item.type == 5"  @click="CopyHearbeatUrl(heartbeat_url+ data.item._id)">{{ heartbeat_url+ data.item._id }}
                      <!-- <textarea  class="f-w-700 text-center long_text" :value="heartbeat_url+ data.item._id" name="" cols="30" rows="1" style="" disabled />
                      <b-tooltip :target="data.item._id" triggers="hover" placement="top">{{ heartbeat_url+ data.item._id }}</b-tooltip> -->
                    </span>
                    <span v-else @click="CopyHearbeatUrl(data.item.url)">{{ data.item.url }}
                      <!-- <span :id="data.item.url">{{ data.item.url }}</span> -->
                      <!-- <textarea  class="f-w-700 text-center long_text" :value="data.item.url" name="" cols="30" rows="1" style="" disabled /> -->
                    <!-- <b-tooltip :target="data.item._id" triggers="hover" placement="top">{{ data.item.url }}</b-tooltip> -->
                    </span>
                  </div>
                </template>

                <template #cell(action)="data">
                  <feather class="text-danger" type="trash-2" title="Delete" @click="deleteMonitor(data.item._id)" style="cursor: pointer;"></feather>
                  <feather class="text-dark ml-2" type="edit" title="Edit" @click="editMonitor(data.item)" style="cursor: pointer;"></feather> 
                  <feather class="text-dark ml-2" type="pie-chart" title="Report" @click="openMonitoReport(data.item)" style="cursor: pointer;"></feather>
                  <!-- <router-link :to="{name:`Monitor Report`,params:{id:data.item._id}}">
                    <feather class="text-primary ml-2" type="pie-chart" title="Report"></feather>
                  </router-link>  -->
                </template>
                </b-table>
              </div>
              <b-col md="12" class="mt-3 text-right p-0">
                <div class="pagination">
                  <a v-show="currentPage != 1" @click="getMonitors('prev')">&laquo; Back</a>
                  <a class="active">{{currentPage}}</a>
                  <a v-show="items.length >= perPage" @click="getMonitors('next')">Next &raquo;</a>
                </div>
              </b-col>
            </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-monitor" size="lg" title="Monitor" class="theme-modal" @hidden="closeModal" v-model="add_monitor_modal" no-close-on-backdrop hide-footer>
        <add-monitor :add_monitor_modal="add_monitor_modal" @monitormodal="closeModal" :edit_monitor="edit_monitor" :monitor_data="monitor_data"></add-monitor>
        <!-- <template #modal-footer>
        <b-button @click="closeModal()" color="danger">Close</b-button>
        </template> -->
      </b-modal>

      <!-- Bulk Action Modal Start -->
      <b-modal id="modal-monitor" title="Bulk Action" @hidden="closeBulkModal" size="lg" class="theme-modal" v-model="open_bulk_action" no-close-on-backdrop hide-footer>
        <bulk-action :open_bulk_action="open_bulk_action" @bulkmodal="closeBulkModal()"></bulk-action>
        <!-- <template #modal-header>
          <h5 class="font-weight-bold"><i class="fa fa-edit"></i> Bulk Actions</h5>
        </template> -->
        <!-- <template #modal-footer>
        <b-button @click="closeBulkModal()" color="danger">Close</b-button>
        </template> -->
      </b-modal>
      <!-- Bulk Action Modal End -->


      <b-modal id="import-monitor" size="xl" title="Import Monitor" class="theme-modal" v-model="open_import_monitor" no-close-on-backdrop hide-footer>
        
        <input type="file" 
                id="files" 
                accept=".csv" 
                ref="file"
                @change="validateCsvFile()"
                >
        <span v-if="loading">
          <h6 class="sub-title mb-0 text-center">Uploading...</h6>
            <div class="loader-box">
              <div class="loader-30"></div>
            </div>
          </span>
        <span v-else>
          <b-button size="sm" variant="success" class="btn-pill mt-md-0 mt-3" @click="UploadCsv()">Upload</b-button>
        </span>
        <div class="p-3">
        <b-row class="mt-2">
          <b-col class="col-md-12">
            <div class="f-20 f-w-600 text-info">Creating import file </div>
            
            <ol class="ml-3">
              <li class="link_txt" @click="downloadCSV">Download file template</li>
              <li>Fill the template with your specific monitor attributes (see below for explanation)</li>
            </ol>
            </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <div class="f-18 f-w-500 text-info">Filelds Description</div>
            <div>
              <ol class="ml-3">
                <li><span class="f-w-900">Type</span>: A categorization of the service being monitored, possible values <span class="f-w-900 text-danger">[ HTTP, KEYWORD, PING, PORT, HEARTBEAT, PAGE_SPEED, PAGE_SCREENSHOT ]</span></li>
                <li><span class="f-w-900">Friendly Name</span>: A user-defined name for the service being monitored.</li>
                <li><span class="f-w-900">URL/IP</span>: The URL or IP address of the service or device being monitored.</li>
                <li><span class="f-w-900">Port</span>: The port number on which the service or device is running.</li>
                <li><span class="f-w-900">Keyword Value</span>: A specific value or string that the monitoring system is looking for to determine if the service or device is functioning properly.</li>
                <li><span class="f-w-900">Keyword Type</span>: The type of keyword being monitored, such as "exact match" or "not match". possible values <span class="f-w-900 text-danger"> [ if exists, if not exists] </span></li>
                <li><span class="f-w-900">interval</span>The frequency at which the monitoring system checks the service or device, such as every 5 minutes. possible values <span class="f-w-900 text-danger"> [1, 2, 3, 4, 5, 6, 10, 12, 15, 20, 30, 60, 120, 180, 240, 360, 480, 720, 1440] in minutes</span></li>
                <li><span class="f-w-900">day_count</span>: The number of consecutive days the service or device must be down before triggering an alert. (optional) only available for HEARTBEAT, possible values <span class="f-w-900 text-danger">[1 to 365]</span></li>
                <li><span class="f-w-900">grace_interval</span>: A time period during which the monitoring system will wait before triggering an alert, even if the service or device is down. (optional) only available for HEARTBEAT possible values <span class="f-w-900 text-danger">(30-86400) in seconds</span></li>
                <li><span class="f-w-900">devicetype</span>The type of device being monitored. (optional) only available for PAGE_SCREENSHOT possible values <span class="f-w-900 text-danger">[Desktop, Mobile, Tablet].</span></li>
              </ol>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <div class="f-18 f-w-500 text-primary" @click="downloadSampleData" style="cursor: pointer;">Example (download file)</div>
            <!-- <div>
              <b-table striped hover :items="sampleData" :fields="sampleDatafields">
              </b-table>
            </div> -->
          </b-col>
        </b-row>
      </div>
        <template #modal-footer>
        <b-button @click="open_import_monitor = false" color="danger">Close</b-button>
        </template>
      </b-modal>
      <!-- Bulk import model end  -->
      
      <!-- Monitor Report modal start  -->
      <b-modal id="monitor-report" size="xl" title="Monitor Report" class="theme-modal" v-model="open_monitor_report" hide-footer>
        <template #modal-header>
            <span class="f-24 f-w-700">Monitor Report - <span class="text-info">{{ monitor_data.name }}</span></span>
            <span class="f-20 f-w-700 cursor_pointer" @click="open_monitor_report = false">X</span>
        </template>
        <monitor-report :monitor_id="monitor_id" :monitor_data="monitor_data"></monitor-report>
      </b-modal>
      <!-- Monitor Report modal End  -->

      <!-- Incident Modal start  -->
      <b-modal id="incidentModal" size="xl" title="Incidents" class="theme-modal" v-model="open_incident" hide-footer>
        <incedent-vue :monitor_id="monitor_id" :monitor_data="monitor_data"></incedent-vue>
      </b-modal>
      <!-- Incident Modal End  -->
  </div>
</template>


<script>
import MonitorsController from "../../services/monitorController";
import HelperFunction from "../../services/helperFunction";
import Config from "../../services/config";
import AddMonitor from "./AddMonitor.vue";
import BulkAction from "./BulkAction.vue";
import SampleCsv from "../../services/sampleCsvData";
import MonitorReport from "./MonitorReport.vue";
import IncedentVue from "./Incedent.vue";
export default {
    name: "Monitor",
    components:{AddMonitor,BulkAction, MonitorReport,IncedentVue},
  data() {
    return {
        open_incident:false,
        monitor_id:'',
        monitor_data:[],
        open_monitor_report:false,
        monitorType:'all',
        activeButton: 'all',
        open_bulk_action:false,
        open_import_monitor:false,
        heartbeat_url :'',
        hearbeat_modal:false,
        add_monitor_modal:false,
        edit_monitor: false,
        tablefields: [
        'index',
        { key: "name", label: "Name", sortable: true },
        { key: "type", label: "Monitor Type", sortable: false },
        { key: "status", label: "Status", sortable: false },
        { key: "url", label: "URL", sortable: false },
        { key: "interval", label: "Interval", sortable: false },
        // { key: "timeout", label: "timeout", sortable: true },
        // { key: "ssl_error", label: "ssl_error", sortable: true },
        // { key: "ssl_expiry_reminder", label: "ssl_expiry_reminder", sortable: true },
        // { key: "auth_type", label: "auth_type", sortable: true },
        // { key: "auth_username", label: "auth_username", sortable: true },
        // { key: "auth_password", label: "auth_password", sortable: true },
        // { key: "http_method", label: "http_method", sortable: true },
        // { key: "keyword", label: "keyword", sortable: true },
        // { key: "case_sensitive", label: "case_sensitive", sortable: true },
        // { key: "alertWhen", label: "alertWhen", sortable: true },
        // { key: "port", label: "port", sortable: true },
        // { key: "useremail", label: "useremail", sortable: true },
        //  { key: "createdAt", label: "Created At", sortable: true },
        // { key: "updatedAt", label: "updatedAt", sortable: true },
        { key: "action", label: "Action", sortable: false },
      ],
      items:[],
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [50, 100, 200,500],
      monitor_data:{},
      loading: false,
      monitor_type_opt: [
        { value: 'all', text: 'All Monitors' },
        { value: '1', text: 'HTTP(s)' },
        { value: '2', text: 'Keyword' },
        { value: '3', text: 'Ping' },
        { value: '4', text: 'Port' },
        { value: '5', text: 'Heartbeat' },
        { value: '6', text: 'Page Speed' },
        { value: '7', text: 'Page Screenshot' }
      ],
      sampleDatafields:[],
      sampleData:[],
      all_monitors:[]
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.tablefields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    isMobileScreen() {
        return window.innerWidth <= 1200;
    }
  },
  watch:{
    $route (to, from){
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if(urlParams.get('event')){
          this.add_monitor_modal = true
      }
    },
  },
  mounted() {
    if(this.$root.$data.showAddMoinitor == true){
      this.add_monitor_modal = true
    }
    this.$root.$data.showAddMoinitor = false
    this.heartbeat_url = Config.hertbeatUrl
    if(this.$route.query.type){
      this.activeButton = this.$route.query.type
    }

    this.sampleDatafields = SampleCsv.sampleDatafields
    this.sampleData = SampleCsv.sampleData
    this.getMonitors();
  },
  methods: {
    validateCsvFile() {
    const input = document.getElementById('files');
    const file = input.files[0];
      if (file) {
          const fileName = file.name;
          const fileExtension = fileName.split('.').pop();
          if (fileExtension.toLowerCase() !== 'csv') {
            this.$toasted.show('Please select only .csv file with defined fields in description.', {theme: 'outline',position: "top-center", type: 'error', duration: 5000});
            input.value = '';  // Clear the file input
          }
      }
  },
    openMonitoReport(data){
      this.monitor_id = data._id
      this.monitor_data = data
      this.open_monitor_report = true
    },
    openIncident(data){
      this.monitor_id = data._id
      this.monitor_data = data
      this.open_incident = true
    },
    closeModal(){
      this.add_monitor_modal = false
      this.edit_monitor = false;
      if(this.$router.history.current.fullPath != '/app/monitor'){
      this.$router.replace("/app/monitor");
      }
      this.getMonitors();
    },
    closeBulkModal(){
      this.open_bulk_action = false
      this.getMonitors();
    },
   async deleteMonitor(id){
    if(confirm("Are you sure want to delete?")){
      let response = await MonitorsController.deleteMonitor({"mid": id});
      if(response.result){
        this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
        // this.getMonitors()
        this.items = this.items.filter((t)=> t._id !== id)
      }
      else{
          this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 3000});
          this.getMonitors()
      }
    }
    },
    editMonitor(data){
      this.add_monitor_modal = false
      this.add_monitor_modal = true
      this.monitor_data = data;
      this.edit_monitor = true;
    },
    async getMonitors(txt) {
      if(txt == 'next'){this.currentPage ++}
      if(txt == 'prev'){
        if(this.currentPage != 1){
        this.currentPage --
        }
      }
      var paginationQuery = '&currentPage='+this.currentPage+'&pageSize='+this.perPage

      if(this.monitorType != 'all'){
        this.filterMonitorByType(this.monitorType+paginationQuery)
        return
      }
      this.loading = true;
      this.items = [];
      this.all_monitors = []
      let response = await MonitorsController.getMonitors('?type='+this.activeButton+paginationQuery);
      if (response.result) {
        this.items = response.data;
        this.all_monitors = this.items
        this.totalRows = this.items.length;
      }
      this.loading = false;
    },
    async filterMonitorByType(monitorType) {
      if(monitorType == 'all'){
        this.getMonitors()
        return
      }
      this.loading = true;
      this.items = []
      this.all_monitors = []
      let response = await MonitorsController.getMonitors('?monitortype='+monitorType);
      if (response.result) {
        this.items = response.data;
        this.all_monitors = this.items
        this.totalRows = this.items.length;
      }
      this.sortMonitor(this.activeButton)
      this.loading = false;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async statusUpdate(data){
        // this.loading = true;
        var s = true
        if(data.status == true){
            s = false
        }
        if (data.status == false) {
          data.status = true;
        } else {
          data.status = false;
        }
        var payload = {"mname":data.name,"mid": data._id, "status":s }
        let response = await MonitorsController.statusChange(payload)
        if(response.result){
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
        }
        else{
          this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 3000});
          this.getMonitors()
        }
      },
    CopyHearbeatUrl(str){
          const el = document.createElement('textarea');
          el.addEventListener('focusin', e => e.stopPropagation());
          el.value = str;
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          document.body.removeChild(el);
          this.$toasted.show('URL copied to clipboard.', {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
    },
     ExportMonitor(){
      let uri =  Config.exportMonitor

      let path = `?type=${this.activeButton || ''}&monitortype=${this.monitorType || ''}`
      uri += path

      var link = document.createElement("a");
      link.setAttribute('download', 'monitors');
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      link.remove();
      this.$toasted.show('Monitor Exported', {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
      },
    async UploadCsv(){
      this.loading = true;
      if(this.$refs.file.files[0]){
      var file = this.$refs.file.files[0];
      const formData = new FormData();
        formData.append('sampleFile', file);
        try {
          let response = await MonitorsController.csvUpload(formData)
          if(response.result){
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
            this.open_import_monitor = false;
            this.getMonitors();
          }
          else{
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
          }
        } catch (error) {
          console.log(error)
          this.loading = false;
        }
      }
      else{
        this.$toasted.show('Please select file', {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
      }
      this.loading = false;
   },
   convertMsToHM(millis) {
    return HelperFunction.convertMsToHM(millis*60*1000)
    },
    toggleActive(button) {
        this.activeButton = button;
        if(this.monitorType == 'all'){
          this.getMonitors();
        }
        else{
          this.sortMonitor(button)
        }
    },
    sortMonitor(txt){
      switch(txt){
        case 'up':
          this.items = this.all_monitors.filter((t)=> !t.isDown && t.status)
          break;
        case 'down':
          this.items = this.all_monitors.filter((t)=> t.isDown && t.status)
          break;
        case 'pause':
          this.items = this.all_monitors.filter((t)=> !t.status)
          break;
        default: 
          this.items = this.all_monitors
          break;
      }
    },
    downloadCSV() {
        const data = [this.sampleDatafields]
        const csvContent = "data:text/csv;charset=utf-8," 
                           + data.map(e => e.join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "TemplateBulkImportFile.csv");
        document.body.appendChild(link);
        link.click();
    },
    setPerpage(){
      this.currentPage = 1;
      this.getMonitors();
    },
    downloadSampleData(){
      HelperFunction.downloadCSV(this.sampleData)
    }
  },
};
</script>

<style scoped>
.showMonitorBtn,
.sectionShowMobile {
 display: none !important;
}

.custom-border-06 {
  border: 0.6px solid rgba(0, 0, 0, 0.6) !important;
}

.add-monitor-btn-green-1 {
  font-size: 1.1vw;
  padding: 0.335rem 2.2em !important;
  background-color: #2FB234 !important;
  color: white !important;
  cursor: pointer !important;
  border-radius: 10px !important;
}

.add-monitor-btn-green-1:hover {
  background-color: #139818 !important;
}

/* .sectionShowDesktop .custom-btn-dark {
  margin-right: 1.5rem !important;
}

.sectionShowDesktop .custom-btn-dark:nth-child(3) {
  margin-right: 0.5rem !important;
} */

@media screen and (max-width: 1468px) {
  .add-monitor-btn-green-1 {
    padding: 0.333rem 1rem !important;
  }
}

@media screen and (max-width: 768px) {

  .btn-group-square {
    margin-bottom: 1rem !important;
    width: 100% !important;
  }

  .cus-btn-group .text-right{
    text-align: center !important;
  }

  .sectionShowMobile {
    display: block !important;
  }

  .sectionShowDesktop {
    display: none !important;
  }

  .sectionShowMobile .input-group-square .input-group > .form-control {
    padding-right: 5rem !important;
  }

  .add-monitor-btn-green-1 {
    font-size: 0.8rem !important;
    padding: 0.526rem 0.82rem !important;
    border-radius: 5px !important;
  }
}

@media screen and (max-width: 1200px) {
  .showMonitorBtn{
    display: block !important;
  }
}
</style>


<style>
.long_text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  width: 100%;border:none;background: none;resize: none;word-break: break-all;
}
.long_text:hover {
  resize: vertical;
}
.tooltip-inner{
  background-color: rgb(19, 1, 75) !important;
  color: rgb(255, 255, 255);
  width: 100%;
}
.glowing-dot-up {
  width: 10px;
  height: 10px;
  margin-top: 5px;
  border-radius: 50%;
  background-color: #86f700;
  box-shadow: 0 0 10px #86f700;
  animation: glowup 1s ease-in-out infinite;
}


@keyframes glowup {
  from {
    box-shadow: 0 0 5px #59ff00;
  }
  to {
    box-shadow: 0 0 10px #33ff00;
  }
}
.glowing-dot-down {
  width: 10px;
  height: 10px;
  margin-top: 5px;
  border-radius: 50%;
  background-color: #f70000;
  box-shadow: 0 0 10px #d72b1b;
  animation: glowdown 1s ease-in-out infinite;
}

@keyframes glowdown {
  from {
    box-shadow: 0 0 5px #ff4d00;
  }
  to {
    box-shadow: 0 0 10px #ff2f00;
  }
}
.glowing-dot-pause {
  width: 10px;
  height: 10px;
  margin-top: 5px;
  border-radius: 50%;
  background-color: #d6f700;
  box-shadow: 0 0 10px #d7d71b;
  animation: glowpause 1s ease-in-out infinite;
}

@keyframes glowpause {
  from {
    box-shadow: 0 0 5px #fbe555;
  }
  to {
    box-shadow: 0 0 10px #ddff00;
  }
}
.pause-bg{
  color: white;
  background-color: #818181 !important;
}
.pause-bg:hover{
  color: white;
}
.down-bg{
  color: white;
  background-color: #E70000 !important;
}
.down-bg:hover{
  color: white;
}
.up-bg{
  color: white;
  background-color: #188107 !important;
}
.up-bg:hover{
  color: white;
}

</style>



