<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <form action="" @submit.prevent="onSubmit()">
          <px-card>
            <b-row>
              <b-col>
                <h6 class="text-primary">Action Details</h6>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col md="3" class="font-weight-bold">
                I want to <span class="font-danger">*</span>
              </b-col>
              <b-col md="6">
                <div class="form-group">
                  <b-form-select
                    name="type"
                    size="sm"
                    v-model="action_type"
                    :options="action_opt"
                    :state="validate_action_type"
                    required
                  ></b-form-select>
                  <b-form-invalid-feedback :state="validate_action_type">
                    Select an action type
                  </b-form-invalid-feedback>

                </div>
              </b-col>
            </b-row>
            <hr>
            <div v-if="action_type">
            <b-row>
              <b-col md="3" class="font-weight-bold">
                Applies to <span class="font-danger">*</span>
              </b-col>
              <b-col md="6">
                <div class="form-group">
                  <b-form-select
                    name="type"
                    size="sm"
                    v-model="appliesto"
                    :options="appliesto_opt"
                    :state="isAppliesToSelected ? !invalid_appliesto_message : null"
                    @change="validate_appliesto"
                    required
                  ></b-form-select>
                  <b-form-invalid-feedback v-if="isAppliesToSelected">
                    {{ invalid_appliesto_message }}
                  </b-form-invalid-feedback>
                </div>
              </b-col>
            </b-row>
            <hr>
          </div>
            <div v-if="appliesto==2">
            <b-row>
                <b-col  class="font-weight-bold">
                Select Monitors <span class="font-danger">*</span>
                </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <div class="form-group">
                  <multiselect
                    variant="success"
                    :close-on-select="false"
                    v-model="selected_monitors"
                    tag-placeholder="Select Monitors"
                    placeholder="Search or add a Monitor"
                    label="text"
                    track-by="value"
                    :options="filteredMonitors"
                    :multiple="true"
                    :taggable="true"
                    @tag="addTag"
                    @input="validateSelectedMonitors" 
                    :class="{ 'invalid-select': isMonitorInvalid, 'valid-select': isMonitorValid }"
                  ></multiselect>
                    <div v-if="isMonitorInvalid" class="invalid-feedback d-block">
                    {{ invalid_monitor_message }}
                    </div>
                </div>
              </b-col>
            </b-row>
            <hr>
          </div>
          <div  v-if="action_type==8">
          <b-row>
              <b-col md="3" class="font-weight-bold">
                Alert Frequency <span class="font-danger">*</span>
              </b-col>
              <b-col class="col-md-6 col-12">
                <b-form-select
                  name="type"
                  size="sm"
                  v-model="input.downAlertFreqType"
                  :options="alert_freq_opt"
                  :state="isDownAlertFreqValid ? !invalid_down_alert_message : null" 
                  @change="validateDownAlertFreq"
                  required
                >
                      <b-form-select-option value=1 :disabled="not_premium" title="paid">{{not_premium?'once per hour (pro plan)':'once per hour'}}</b-form-select-option>
                      <b-form-select-option value=2 :disabled="not_premium" title="paid">{{not_premium?'once per day (pro plan)':'once per day'}}</b-form-select-option>
                      <b-form-select-option value=3 :disabled="not_premium" title="paid">{{not_premium?'once per week (pro plan)':'once per week'}}</b-form-select-option>
                      <b-form-select-option value=4 :disabled="not_premium" title="paid">{{not_premium?'once per month (pro plan)':'once per month'}}</b-form-select-option>
                </b-form-select>
                <b-form-invalid-feedback v-if="isDownAlertFreqValid">
                  {{ invalid_down_alert_message }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row><hr>
          </div>
            <div v-if="action_type == 4">
              <b-row>
                <b-col  md="3" class="font-weight-bold">
                  Monitoring Interval 
                </b-col>
                <b-col md="4">
                  <!-- <div class="slidecontainer">
                    <span class="font-weight-bold text-info" v-if="input.interval <=60">every <span class="text-danger">{{input.interval}}</span> minutes</span>
                    <span class="font-weight-bold text-info" v-if="input.interval >=60">every <span class="text-danger">{{Math.round(input.interval /60,2)}}</span> hours</span>
                    <input type="range" :min="plan_details.minIntervalLimit" max="1440" v-model="input.interval" class="slider" id="myRange">
                  </div> -->
                  <div class="slidecontainer">
                    <span class="font-weight-bold text-info" v-if="input.interval <= 60">
                      every <span class="text-danger">{{ input.interval }}</span> minutes
                    </span>
                    <span class="font-weight-bold text-info" v-else>
                      every <span class="text-danger">{{ Math.round(input.interval / 60, 2) }}</span> hours
                    </span>

                    <vue-slider
                      :data="interval.data"
                      v-model="input.interval"
                      :marks="false"
                      :hide-label="true"
                      :tooltip="'none'"
                      :min="plan_details.minIntervalLimit"
                      :max="1440"
                    ></vue-slider>
                  </div>
                </b-col>
              </b-row><hr>
            </div>
            <div v-if="action_type == 5">
              <b-row>
                <b-col  md="3" class="font-weight-bold">
                  Monitoring Timeout
                </b-col>
                <b-col md="4">
                  <div class="slidecontainer">
                    <span class="font-weight-bold text-info">
                      in <span class="text-danger">{{ input.timeout }}</span> sec
                    </span>

                    <vue-slider
                      :data="timeout.data"
                      v-model="input.timeout"
                      :marks="false"
                      :hide-label="true"
                      :tooltip="'none'"
                      :min="1"
                      :max="60"
                    ></vue-slider>
                  </div>
                </b-col>
              </b-row><hr>
            </div>
            <div v-if="action_type == 9">
              <b-row>
                <b-col  md="3" class="font-weight-bold">
                  Grace Timeout
                </b-col>
                <b-col md="4">
                  <div class="slidecontainer">
                    <span class="font-weight-bold text-info">
                      in <span class="text-danger">{{ input.grace_interval }}</span> sec
                    </span>
                    <vue-slider
                      :data="grace_timeout_datas"
                      v-model="input.grace_interval"
                      :marks="false"
                      :hide-label="true"
                      :tooltip="'none'"
                      :min="1"
                      :max="60"
                    ></vue-slider>
                  </div>
                </b-col>
              </b-row><hr>
            </div>
            <div v-if="action_type == 6">
                <b-row>
                  <b-col class="font-weight-bold">
                    Select Alert Contacts To Notify <span class="font-danger">*</span>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col>
                    <multiselect
                      :close-on-select="false"
                      size="sm"
                      v-model="alertContacts"
                      tag-placeholder="Add this as new tag"
                      placeholder="Select alerts"
                      label="text"
                      track-by="value"
                      :options="alerts"
                      :multiple="true"
                      :taggable="true"
                      @tag="addTag"
                      @input="validateAlertContacts"
                      :class="{ 'invalid-select': isAlertInvalid, 'valid-select': isAlertValid }"
                    ></multiselect>
                    <div v-if="isAlertInvalid" class="invalid-feedback d-block">
                      {{ invalid_alert_message }}
                    </div>
                   </b-col>
                </b-row><hr>
            </div>
            <div v-if="action_type == 7">
              <b-row class="">
                  <b-col md="3" class="font-weight-bold">
                      Monitor SSL errors
                  </b-col>
                  <b-col md="1">
                      <input class="checkbox_animated" name="ssl_error" type="checkbox" v-model="input.ssl_error">
                  </b-col>
              </b-row><hr>
              <b-row class="" >
                  <b-col md="3" class="font-weight-bold">
                      Enable SSL expiry reminders
                  </b-col>
                  <b-col md="1">
                      <input class="checkbox_animated" name="ssl_expiry_reminder" type="checkbox" v-model="input.ssl_expiry_reminder">
                  </b-col>
                  <b-col md="">
                      <span class="text-info"><small>Monitor URL needs to start with "https" for the SSL monitoring to work.</small></span>
                  </b-col>
                </b-row><hr>
            </div>
            <div v-if="action_type">
            <b-row>
              <b-col>
                <h6 class="text-primary">Action Approval</h6>
              </b-col>
            </b-row><hr>
            <b-row>
              <b-col>
              Please write&nbsp;"<span class="text-info"><b>{{action_txt[action_type]}}</b></span>"&nbsp;to  the field below to approve the action.
            </b-col>
            </b-row><hr>
            <b-row>
              <b-col md="3" class="font-weight-bold">
                Approval Text <span class="font-danger">*</span>
              </b-col>
              <b-col>
                <b-form-input size="sm" type="text" placeholder="Approval Text" v-model="approval_text" :state="isTextEntered ? !invalid_approval_message : null" @input="validateApprovalText" required></b-form-input>
                <b-form-invalid-feedback v-if="isTextEntered">
                  {{ invalid_approval_message }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <hr>
          </div>
          <span v-if="isloading">
            <div class="loader-box">
              <div class="loader-30"></div>
            </div>
          </span>
            <b-row class="mt-2">
              <b-col class="text-center">
                <b-button type="submit" variant="primary" :disabled="isloading">Complete Action</b-button>
              </b-col>
            </b-row>
          </px-card>
        </form>
      </div>
    </div>
  </div>
</template>



<script>
import Multiselect from 'vue-multiselect'
import MonitorsController from "../../services/monitorController";
import VueSlider from 'vue-slider-component';
export default {
  name: "BulkAction",
  components:{Multiselect, VueSlider},
  data() {
    return {
      isloading:false,
      alerts:[],
      alertContacts:[],
      plan_details:{minIntervalLimit:5},
      approval_text:'',
      input:{monitors:[],changePayload:{},interval:5,timeout:5,grace_interval:30, ssl_expiry_reminder:false,ssl_error:false,downAlertFreqType:null},
      action_type:null,
      appliesto:null,
      action_opt: [
        { value: null, text: "Please Select" },
        { value: "1", text: "Pause Monitors" },
        { value: "2", text: "Start Monitors" },
        { value: "3", text: "Delete Monitors" },
        { value: "4", text: "Change Intervals Of Monitors" },
        { value: "5", text: "Change Timeouts Of Monitors" },
        { value: "6", text: "Change Alert Contacts Of Monitors" },
        { value: "7", text: "Change SSL Settings Of Monitors" },
        { value: "8", text: "Change Alert Frequency Of Monitors" },
        { value: "9", text: "Change Grace Timeout Of Monitors" },
      ],
      appliesto_opt:[
        { value: null, text: "Please Select" },
        { value: "1", text: "For all the monitors" },
        { value: "2", text: "For selected monitors" },
      ],
      action_txt:{1:'PAUSE MONITORS',2:'START MONITORS',3:'DELETE MONITORS',4:'CHANGE INTERVAL',
                 5:'CHANGE TIMEOUT', 6:'CHANGE ALERT CONTACTS',7:'CHANGE SSL', 8:'CHANGE ALERT FREQUENCY', 9:'CHANGE GRACE TIMEOUT'},
      monitor_opt:[],
      all_monitors:[],
      selected_monitors:null,
      not_premium:false,
      alert_freq_opt: [
              { value: null, text:'select frequency' },
              { value: 0, text:'once' },
      ],
      interval: {
        data: [
          5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 120, 180, 240, 300, 360,
          420, 480, 540, 600, 660, 720, 780, 840, 900, 960, 1020, 1080, 1140,
          1200, 1260, 1320, 1380, 1440,
        ],
      },
      timeout: {
          range: [
          {
              label: "1 minutes",
          },
          {
              label: "minutes",
          },
          ],
      },
      grace_timeout_datas:[30,60,120,180,300,600,1200,1800,3600,7200,10800,18000,36000,54000,72000,86400],
      invalid_appliesto_message: 'Please select an applies to',
      isAppliesToSelected: false,
      invalid_monitor_message: 'Please select at least one monitor',
      isMonitorInvalid: false,
      isMonitorValid: false,
      invalid_alert_message: 'Please select at least one alert contact',
      isAlertInvalid: false,
      isAlertValid: false,
      isDownAlertFreqValid: false,
      invalid_down_alert_message: 'Please select an alert frequency',
      isTextEntered: false,
      invalid_approval_message: 'Approval text is required',
    };
  },
  computed: {
    filteredMonitors() {
      if (this.action_type == 9) {
        return this.monitor_opt.filter(m => m.type == 5);
      }
      return this.monitor_opt;
    },
    validate_action_type() {
      return this.action_type !== null && this.action_type !== undefined;
    },
  },
  mounted(){
    this.plan_details = this.$root.$data.plan_details
    if(this.plan_details.plan_type == '1'){
            this.not_premium = true
    }
    this.getMonitors(); 
    this.getAlerts();
    },
    methods:{
      async onSubmit(){
        if(this.validate()){
        this.isloading = true
        let monitors = []
        if(this.selected_monitors != null){
          this.selected_monitors.map((t)=>{ monitors.push(t.value)})
        }
        var alertContacts = []
        if(this.alertContacts != null){
            this.alertContacts.map((l)=>{
                alertContacts.push(l.value)
            })
        }
        let payload={}
        switch(this.action_type){
          case '1': payload={'monitors':monitors,'changePayload':{'status': false}}
            break;
          case '2':payload={'monitors':monitors,'changePayload':{'status': true}}
            break;
          case '3':payload={'monitors':monitors,'changePayload':{'isDelete': true}}
            break;
          case '4':payload={'monitors':monitors,'changePayload':{'interval': this.input.interval}}
            break;
          case '5':payload={'monitors':monitors,'changePayload':{'timeout': this.input.timeout}}
            break;
          case '6':payload={'monitors':monitors,'changePayload':{'alertContacts': alertContacts}}
            break;
          case '7':payload={'monitors':monitors,'changePayload':{'ssl_expiry_reminder':this.input.ssl_expiry_reminder,ssl_error:this.input.ssl_error}}
            break;
          case '8':payload={'monitors':monitors,'changePayload':{'downAlertFreqType':this.input.downAlertFreqType}}
            break;
          case '9':payload={'monitors':monitors,'changePayload':{'grace_interval':this.input.grace_interval}}
            break;
        }
        let response = await MonitorsController.bulkAction(payload);
        if(response.result){
          this.approval_text=''
          this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
          this.$emit('bulkmodal')
        }
        else{
          this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
        }
        this.isloading = false
      }
      },
      validate(){
        if(this.appliesto == 2 && this.selected_monitors == null){
          this.$toasted.show('Please select monitor!', {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
          return false;
        }
        else if(this.action_type == 6 && this.alertContacts.length == 0){
          this.$toasted.show('Please select alert contacts!', {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
          return false;
        }
        else if(this.approval_text === this.action_txt[this.action_type]){
          return true;
        }
        else{
          this.$toasted.show('Please enter correct approval text!', {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
          return false;
        }
      },
      async getAlerts(){
            let response= await MonitorsController.getAlerts()
            if(response.data){
            response.data.map((t)=>{
                if(t.status){
                this.alerts.push({ value: t._id, text:t.name })
                }
            })
            }
         },
      async getMonitors() {
      let response = await MonitorsController.getMonitors();
      if (response.result) {
          response.data.map((t) => {
          this.monitor_opt.push({ value: t._id, text: t.name, type:t.type,isDown:t.isDown });
          this.all_monitors.push({ value: t._id, text: t.name, type:t.type,isDown:t.isDown });
          });
      }
      },
      addTag(newTag) {
        const tag = {
          name: newTag,
          code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
        };
        this.options.push(tag);
        this.selected_monitors.push(tag);
        this.validateSelectedMonitors();
      },
      validateSelectedMonitors() {
        if (!this.selected_monitors || this.selected_monitors.length === 0) {
          this.isMonitorInvalid = true;
          this.isMonitorValid = false;
          this.invalid_monitor_message = 'Please select at least one monitor';
        } else {
          this.isMonitorInvalid = false;
          this.isMonitorValid = true;
          this.invalid_monitor_message = '';
        }
      },
      validateAlertContacts() {
        if (!this.alertContacts || this.alertContacts.length === 0) {
          this.isAlertInvalid = true;
          this.isAlertValid = false;
          this.invalid_alert_message = 'Please select at least one alert contact';
        } else {
          this.isAlertInvalid = false;
          this.isAlertValid = true;
          this.invalid_alert_message = '';
        }
      },
      validate_appliesto() {
        this.isAppliesToSelected = true;
        if (!this.appliesto) {
          this.invalid_appliesto_message = 'Please select a applies to';
        } else {
          this.invalid_appliesto_message = '';
        }
      },
      validateDownAlertFreq() {
        this.isDownAlertFreqValid = true;
        if (!this.input.downAlertFreqType && this.input.downAlertFreqType != 0 ) {
          this.invalid_down_alert_message = 'Please select an alert frequency';
        } else {
          this.invalid_down_alert_message = '';
        }
      },
      validateApprovalText() {
        this.isTextEntered = true;
        if (!this.approval_text) {
          this.invalid_approval_message = 'Approval text is required';
        } else if (this.approval_text != this.action_txt[this.action_type]) {
          this.invalid_approval_message = 'Please enter correct approval text';
        } else {
          this.invalid_approval_message = '';
        }
      },
    }
};
</script>


<style scoped>
hr {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}
/* The slider itself */
.slider {
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    height: 25px; /* Specified height */
    background: #d3d3d3; /* Grey background */
    outline: none; /* Remove outline */
    opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    width: 100%;
}

/* Mouse-over effects */
.slider:hover {
    opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */ 
.slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 15px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #1f9022; /* Green background */
    cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #1f9022; /* Green background */
    cursor: pointer; /* Cursor on hover */
}
</style>